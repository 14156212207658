import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBarsStaggered, FaCoins, FaLocationDot } from "react-icons/fa6";
import logo from "../assets/logo.jpg";
import mobile_logo from "../assets/mobile_logo.jpg";
import {
  countries,
  europeanCountries,
  LINKS,
  REACT_APP_KEY_NAME,
} from "../utils/constant";
import Sidebar from "./Sidebar";
import { useAuth } from "../context/auth";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useCurrency } from "../context/currency";
import { ISelectOption } from "../types/common";
import { useLocationContext } from "../context/location";
import { MdCancel } from "react-icons/md";
import { RiAuctionFill } from "react-icons/ri";
const Header: React.FC = () => {
  const location = useLocation();
  const { user } = useAuth();
  const [tooltipVisible, setTooltipVisible] = useState(
    () => sessionStorage.getItem("tooltipVisible") !== "false",
  );
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const { pathname } = useLocation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const history = useHistory();
  const activeLink = (path: string) => {
    if (location.pathname === path) return "text-primary font-medium";
    return "text-main";
  };
  const isHomePage = location.pathname === "/";
  const displayedLogo = isMobile ? mobile_logo : logo;
  const handleLogout = () => {
    Cookies.remove(REACT_APP_KEY_NAME);
    localStorage.removeItem(REACT_APP_KEY_NAME);
    history.push("/");
    window.location.reload();
  };
  const [currency, setCurrency] = useState("USD");

  const {
    selectedLocation,
    setSelectedLocation,
    locationOptions,
    updateUserLocation,
  } = useLocationContext();

  const [currencyChanged, setCurrencyChanged] = useState(false);
  const { userCurrency, updateUserCurrency } = useCurrency();

  const handleCurrencyChange = (newCurrency: string) => {
    updateUserCurrency(newCurrency);
  };
  const handleLocationChange = (newLocation: string) => {
    updateUserLocation(newLocation);
  };
  useEffect(() => {
    setToggleSidebar(false);
    // @ts-expect-error ts-ignore
    document.querySelector("body").style.overflowY = "scroll";
  }, [location.pathname]);
  useEffect(() => {
    if (!isHomePage && tooltipVisible) {
      setTooltipVisible(false);
      sessionStorage.setItem("tooltipVisible", "false"); // Disable tooltip for future visits
    }
  }, [isHomePage, tooltipVisible]);
  return (
    <header className="bg-white">
      <div className="max-w-[1367px] mx-auto px-4 xl:px-6 w-full flex gap-3 justify-between items-center py-1 md:py-2 relative z-20">
        <Sidebar
          user={user}
          handleLogout={handleLogout}
          toggle={toggleSidebar}
          setToggle={setToggleSidebar}
          activeLink={activeLink}
        />

        <Link to="/">
          <img
            className="w-[175px] md:w-[200px]"
            src={displayedLogo}
            alt="Numisnest Logo"
          />
        </Link>

        <FaBarsStaggered
          size={24}
          fill="var(--primary)"
          className="md:hidden cursor-pointer max-w-[50%]"
          onClick={() => [
            setToggleSidebar(true),
            // @ts-expect-error ts-ignore
            (document.querySelector("body").style.overflowY = "hidden"),
          ]}
        />

        <div className="flex items-center gap-1 whitespace-nowrap max-lg:hidden">
          {/* This will ensure the links are in a row */}
          <div className="flex gap-2">
            {LINKS?.map((itm, idx) => (
              <div
                className="relative"
                key={idx}
              >
                {/* Home Link with Dropdown */}
                {itm.path === "/" ? (
                  <div
                    onMouseEnter={() => setDropdownVisible(true)}
                    onMouseLeave={() => setDropdownVisible(false)}
                  >
                    <Link
                      to={itm.path}
                      className={`max-sm:hidden parent-link relative text-base 2xl:text-xl hover:text-primary p-4 hover:transition ${activeLink(
                        itm.path,
                      )}`}
                    >
                      {itm.title}
                    </Link>

                    {dropdownVisible && (
                      <div className="absolute top-full left-0 bg-white shadow-lg border rounded-lg mt-1 z-50">
                        <Link
                          to="/items"
                          className="block px-6 py-4 text-lg  text-main hover:bg-gray-100 hover:text-primary transition-all"
                        >
                          Items
                        </Link>
                        <Link
                          to="/sellers"
                          className="block px-6 py-4 text-lg  text-main hover:bg-gray-100 hover:text-primary transition-all"
                        >
                          Sellers
                        </Link>
                      </div>
                    )}
                  </div>
                ) : (
                  <Link
                    key={idx}
                    to={itm.path}
                    className={`max-sm:hidden parent-link relative text-base 2xl:text-xl hover:text-primary p-4 hover:transition ${activeLink(
                      itm.path,
                    )}`}
                  >
                    {itm.title}
                    {itm.path === "/auctions" && (
                      <RiAuctionFill
                        fill="var(--primary)"
                        className="inline-block ml-[0.1rem] mb-[0.1rem] text-primary"
                      />
                    )}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="flex max-md:hidden items-center gap-6 ml-auto">
          {/* Location Select */}
          {!pathname.startsWith("/seller/") &&
            !pathname.startsWith("/collection/") &&
            !pathname.startsWith("/user-items/") &&
            !pathname.startsWith("/hidden/") && (
              <div className="flex items-center gap-2">
                <FaLocationDot
                  size={22}
                  fill="var(--primary)"
                />
                <select
                  value={selectedLocation}
                  onChange={e => handleLocationChange(e.target.value)}
                  className="rounded-sm outline-none border border-primary px-3 py-1 text-sm md:max-w-[120px] lg:max-w-[140px]"
                >
                  <option value="">Everywhere</option>
                  {locationOptions.map((country, idx) => (
                    <option
                      key={idx}
                      value={country.value}
                    >
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
          {/* Currency Select */}
          <div className="max-md:hidden flex items-center gap-2">
            <FaCoins
              size={22}
              fill="var(--primary)"
            />
            <select
              value={userCurrency}
              onChange={e => handleCurrencyChange(e.target.value)}
              className="rounded-sm outline-none border border-primary px-3 py-1 text-sm"
            >
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="ILS">ILS</option>
            </select>
          </div>
          <div className="flex gap-4 max-md:hidden relative">
            {!user ? (
              <>
                <Link
                  to="/auth/login"
                  className="rounded-lg border border-primary text-primary bg-[#ffffff] whitespace-nowrap text-base text-center px-4 py-2"
                >
                  Login
                </Link>
                <Link
                  to="/auth/signup"
                  className="rounded-lg border border-primary bg-primary text-[#ffffff]  whitespace-nowrap text-base text-center px-4 py-2"
                >
                  Sign up
                </Link>
                <div>
                  {!user && tooltipVisible && isHomePage && (
                    <div
                      style={{
                        width:
                          window.screen.width > 1080 &&
                          window.screen.width < 1400
                            ? "140px !important"
                            : "",
                      }}
                      className="absolute top-full md:min-w-[140px] bg-primary  xl:min-w-[170px] 2xl:min-w-[180px] left-[70%] font-semibold transform -translate-x-1/2 mt-2 text-white  border-primary shadow-lg p-2 border rounded-lg md:text-sm lg:text-sm text-primary z-50"
                    >
                      {`Any collectibles you want to sell? Sign up! It's completely free!`}
                      <button
                        onClick={() => setTooltipVisible(false)}
                        className="absolute top-0 right-0 mt-1 mr-1 text-red-600"
                      >
                        <MdCancel style={{ fill: "white" }} />
                      </button>
                      <div className="tooltip-tail"></div>{" "}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={handleLogout}
                  className="cursor-pointer whitespace-nowrap rounded-lg border border-primary text-primary bg-[#ffffff] text-base text-center px-4 py-2"
                >
                  Log out
                </div>
                <Link
                  to={`/seller/${user._id}`}
                  className="cursor-pointer rounded-lg border border-primary bg-primary text-[#ffffff] text-base text-center px-4 py-2"
                >
                  Profile
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React, { useEffect, useState, useRef } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Button from "../common/Button";
import Item from "../components/Items/Item";
import avatar from "../assets/avatar.jpg";
import truck from "../assets/profile/truck.png";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import Api from "../api/api";
import { IProps } from "../components/Items/Item";
import { useAuth } from "../context/auth";
import { Helmet } from "react-helmet";
import logo2 from "../assets/logo2.png";
import {
  FaHeart,
  FaRegHeart,
  FaSpinner,
  FaShareFromSquare,
} from "react-icons/fa6";
import toast from "react-hot-toast";
import Modal from "react-modal";
import AdminHeader from "../components/Admin/AdminHeader";
import { FaTimes, FaUserCog } from "react-icons/fa";
import { allKeywords, europeanCountries } from "../utils/constant";
import { useCurrency } from "../context/currency";
import ImageHandler from "../components/ImageHandler";
import { isMobile } from "react-device-detect";
import { isVideo } from "../utils/helpers";
import Loader from "../common/Loader";
import topRatedBadge from "../assets/top_rated_badge.svg";
import CountdownProps from "../components/common/CountdownProps";
import { AIProps } from "../components/Items/AuctionItem";
import BidOrBuyModal from "../components/BidOrBuyModal";
import { min, set } from "date-fns";
import { RiAuctionFill } from "react-icons/ri";
import { usePhoneVerification } from "../components/PhoneVerification/usePhoneVerification";
import { PhoneVerificationModals } from "../components/PhoneVerification/PhoneVerificationModals";

import MetaTags from "../components/common/MetaTags";
import useSEO from "../hooks/useSeo";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
Modal.setAppElement("#root");
const AuctionItem: React.FC = () => {
  const [item, setItem] = useState<AIProps | null>(null);
  const [seller, setSeller] = useState<any>();
  const { user, isAdmin } = useAuth();
  const [inputValue, setInputValue] = useState<string>("");
  const [imageHeight, setImageHeight] = useState<string>("80vh");
  const [zoomContainerHeight, setZoomContainerHeight] =
    useState<string>("100%");
  const [hoveredTopRated, setHoveredTopRated] = useState<boolean | null>(null); // For tooltip
  const locationLocal = useLocation<any>();
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState<any>();
  const [isFavorite, setIsFavorite] = useState<boolean>(
    user?.favorites?.includes(item?._id) ?? false,
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [pinned, setPinned] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [lastSelectedPhoto, setLastSelectedPhoto] = useState<string>(
    "https://via.placeholder.com/350",
  );
  const {
    phoneNumber,
    setPhoneNumber,
    otpCode,
    setOtpCode,
    isPhoneModalOpen,
    isOtpModalOpen,
    openPhoneModal,
    closePhoneModal,
    closeOtpModal,
    sendVerification,
    verifyCode: verifyCodeHook,
    loading,
  } = usePhoneVerification(user);
  const [zoomOrigin, setZoomOrigin] = useState<{
    xPercent: number;
    yPercent: number;
  }>({
    xPercent: 50,
    yPercent: 50,
  });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  const [includeItemReference, setIncludeItemReference] = useState(true);
  const [isImageHandlerOpen, setIsImageHandlerOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [similarItems, setSimilarItems] = useState<IProps[]>([]);
  const { id } = useParams<{ id: string }>();
  const [isZoomedIn, setIsZoomedIn] = useState(false);
  const zoomRef = useRef<HTMLImageElement>(null);
  const history = useHistory();
  const imgRef = useRef<HTMLImageElement>(null);
  const [photoHeight, setPhotoHeight] = useState<number>(0);
  const [selectedPhoto, setSelectedPhoto] = useState<string>(
    "https://via.placeholder.com/350",
  );
  const { userCurrency, convert } = useCurrency();
  const [remainingTime, setRemainingTime] = useState<number>(0);
  const [customPrice, setCustomPrice] = useState<any>(0);
  const [customCurrency, setCustomCurrency] = useState<any>(userCurrency);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCustomBid, setIsCustomBid] = useState(true);
  const [bidCurrency, setBidCurrency] = useState(userCurrency);
  const [userBid, setUserBid] = useState<any>(null);
  const [selectedMultiplier, setSelectedMultiplier] = useState<number | null>(
    null,
  );
  const [userAuctionsCount, setUserAuctionsCount] = useState<number>(0);
  const [highestBid, setHighestBid] = useState<number>(0);
  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const generateBidOptions = (startingBid: number): number[] => {
    const ranges = [
      { max: 5, increment: 1 },
      { max: 30, increment: 2 },
      { max: 100, increment: 5 },
      { max: 260, increment: 10 },
      { max: 500, increment: 20 },
      { max: 800, increment: 25 },
      { max: 1200, increment: 50 },
      { max: 3000, increment: 100 },
      { max: 4000, increment: 200 },
      { max: 7000, increment: 250 },
      { max: 25000, increment: 500 },
      { max: 100000, increment: 1000 },
    ];

    const options: number[] = [];
    let currentBid = startingBid;
    let count = 0; // Keep track of the number of options generated

    // Generate minimum bid amounts with increments
    for (const range of ranges) {
      while (currentBid <= range.max && count < 100) {
        const formattedValue =
          currentBid > 20
            ? Math.round(currentBid)
            : parseFloat(currentBid.toFixed(1));
        options.push(formattedValue);
        currentBid += range.increment;
        count++; // Increment the count for each option added
      }
      if (count >= 100) break; // Stop once 100 options are generated
    }

    // Convert the generated bid options to the user currency
    const convertedOptions = options.map(bid =>
      convert(
        item?.currency === userCurrency ? bid : bid,
        item?.currency || "ILS",
        userCurrency,
      ),
    );
    if (!userBid?.isWinning && item?.hasBid) convertedOptions.shift();

    return convertedOptions;
  };

  const calculateMinimumBid = (currentBid: number) => {
    const ranges = [
      { max: 5, increment: 1 },
      { max: 30, increment: 2 },
      { max: 100, increment: 5 },
      { max: 260, increment: 10 },
      { max: 500, increment: 20 },
      { max: 800, increment: 25 },
      { max: 1200, increment: 50 },
      { max: 3000, increment: 100 },
      { max: 4000, increment: 200 },
      { max: 7000, increment: 250 },
      { max: 25000, increment: 500 },
      { max: 100000, increment: 1000 },
    ];

    for (const range of ranges) {
      if (currentBid <= range.max) {
        return currentBid + range.increment;
      }
    }

    // If the bid exceeds all ranges, use the largest increment
    return currentBid + 1000;
  };
  // Extract the 'from' value from location.state
  const fromSellerPage = locationLocal.state?.fromSellerPage;
  // Determine if the user is viewing their own auction
  const isMyAuction = user && item && user._id === item.uid._id;

  const backButtonText = !fromSellerPage
    ? "Back to Auctions"
    : isMyAuction
      ? "Back to My Auctions"
      : `Back to ${seller?.fname}'s Auctions`;

  const backButtonLink = fromSellerPage
    ? `/seller/${item?.uid?._id}/auctions`
    : `/auctions`;

  const currencySymbol =
    userCurrency === "USD" ? "$" : userCurrency === "EUR" ? "€" : "₪";
  const minimumBid = highestBid || item?.price || 0;
  const bidOptions = generateBidOptions(minimumBid);
  const [bidAmount, setBidAmount] = useState<number>(0);
  const shareUrl = `${window.location.origin}/item/${id}`;
  useEffect(() => {
    getItemByID();
    console.log(backButtonLink);
    console.log(backButtonText);
    console.log(isMyAuction);
    console.log(fromSellerPage);
  }, [id]);
  useEffect(() => {
    if (item) {
      getSimilarItems();
    }
  }, [item, item?._id]);
  useEffect(() => {
    if (minimumBid > 0 && bidAmount === 0) {
      setBidAmount(Number(bidOptions[0]));
    }
  }, [minimumBid, bidAmount]);
  useEffect(() => {
    if (userBid?.isWinning) setBidAmount(userBid.originalBidAmount);
    else setBidAmount(Number(bidOptions[0]));
  }, [userBid]);

  useEffect(() => {
    if (minimumBid > 0) {
      setBidAmount(Number(bidOptions[0]));
    }
  }, [userCurrency]);
  useEffect(() => {
    if (item?.price && item?.currency && user) {
      const temp: any = convert(item.price, item.currency, userCurrency);
      if (temp) {
        setCustomPrice(temp);
        setCustomCurrency(userCurrency);
      }
    } else {
      setCustomPrice(item?.price);
      setCustomCurrency(item?.currency);
    }
  }, [item, user]);
  async function getSimilarItems() {
    try {
      const res = await Api.getSimilarItems(id);
      if (res.status === 200) {
        setSimilarItems(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching similar items:", error);
    }
  }
  const calculateImageSize = () => {
    if (imgRef.current) {
      const { width, height } = imgRef.current.getBoundingClientRect();

      setImageSize({ width, height });
    }
  };
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      console.log("Navigation Action:", action);
      console.log("Location:", location);
      if (action === "PUSH" || action === "REPLACE") {
        sessionStorage.removeItem("itemsScrollPosition");
        sessionStorage.removeItem("itemsCurrentPage");
        console.log(
          "Internal navigation detected: sessionStorage items removed.",
        );
      }
    });

    return () => {
      unlisten();
    };
  }, []);
  const serialize = params =>
    Object.keys(params)
      .map(param => `${param}=${encodeURIComponent(params[param].trim())}`)
      .join("&");
  const handleShare = async () => {
    const url = `https://numisnest.com/item/${id}`;

    const title = item?.name || "Check out this item!";

    const imageUrl =
      item?.photos?.[0]?.url || "https://via.placeholder.com/350";

    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: title,
          url: url,
        });
        console.log("Content shared successfully");
        return;
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    }
    if (navigator.share && navigator.canShare) {
      try {
        // Attempt to fetch the image as a blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const filesArray = [
          new File([blob], "item.jpeg", {
            type: blob.type,
          }),
        ];

        if (navigator.canShare({ files: filesArray })) {
          await navigator.share({
            title: title,
            text: title,
            url: url,
            files: filesArray,
          });
          console.log("Content shared successfully with image");
          return;
        } else {
          await navigator.share({
            title: title,
            text: title,
            url: url,
          });
          console.log("Content shared successfully with image");
          return;
        }
      } catch (error) {
        console.error("Error sharing content with image: ", error);
      }
    } else {
      // Fallback to Facebook share with quote
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(title)}`,
        "_blank",
        "noopener,noreferrer",
      );
    }
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(currencySymbol, "");

    // Allow only numbers and a single decimal point
    if (/^\d*\.?\d*$/.test(value)) {
      setInputValue(value ? `${currencySymbol}${value}` : currencySymbol);
    }
  };
  const getUserBidForItem = async () => {
    try {
      const res = await Api.getUserBidForItem(id);
      if (res.status === 200) {
        setUserBid(res.data.bid);
      }
    } catch (error) {
      console.error("Error fetching user bid:", error);
    }
  };
  useEffect(() => {
    getUserBidForItem();
  }, [user]);
  useEffect(() => {
    calculateImageSize(); // Calculate image size when component mounts or image changes
    window.addEventListener("resize", calculateImageSize); // Recalculate on window resize
    return () => window.removeEventListener("resize", calculateImageSize); // Cleanup
  }, [selectedPhoto]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownVisible &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownVisible]);
  useEffect(() => {
    getCategory(item?.category);
    getSeller(item?.uid);
    if (item?.photos && item.photos.length > 0) {
      setSelectedPhoto(item.photos[0].url);
      setLastSelectedPhoto(item.photos[0].url);
    }
  }, [item]);

  useEffect(() => {
    setIsFavorite(user?.favorites?.includes(item?._id) ?? false);
  }, [user]);
  async function getItemByID() {
    try {
      const [itemRes, bidRes] = await Promise.all([
        Api.getAuctionItemByID(id),
        Api.getUserBidForItem(id),
      ]);
      if (itemRes.status === 200) {
        setItem(itemRes?.data?.data);
        setUserAuctionsCount(itemRes?.data?.data?.auctionsCount || 0);

        if (item?.price && item?.currency && user) {
          const temp = convert(item?.price, item?.currency, userCurrency);

          setCustomPrice(temp);
        }
        setRemainingTime(itemRes?.data?.data?.remainingTime || 0);
        setPinned(itemRes?.data?.data.isPinned);
      }
      if (bidRes.status === 200) {
        setUserBid(bidRes.data.bid || null);
      }
    } catch (error) {
      console.error("Failed to fetch item:", error);
    }
  }
  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          const width = zoomRef.current.naturalWidth;
          const height = zoomRef.current.naturalHeight;
          const aspectRatio = width / height;
          if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setImageHeight("20vh");
          } else if (aspectRatio < 1.5 && window.innerWidth < 1024) {
            setImageHeight("40vh");
          } else if (aspectRatio < 1.5 && window.innerWidth > 1024) {
            setImageHeight("50vh");
          } else {
            setImageHeight("50vh");
          }
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isZoomedIn &&
        zoomRef.current &&
        !zoomRef.current.contains(event.target as Node)
      ) {
        setIsZoomedIn(false);
        zoomRef.current.style.transformOrigin = "center center";
      }
    };

    if (isZoomedIn) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isZoomedIn]);

  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          const width = zoomRef.current.naturalWidth;
          const height = zoomRef.current.naturalHeight;
          const aspectRatio = width / height;
          if (aspectRatio > 1.5 && window.innerWidth > 1320) {
            setZoomContainerHeight("50vh");
          } else if (aspectRatio > 1.5 && window.innerWidth > 680) {
            setZoomContainerHeight("50vh");
          } else if (aspectRatio < 0.67 && window.innerWidth < 680) {
            setZoomContainerHeight("77vh");
          } else if (aspectRatio < 1 && window.innerWidth < 680) {
            setZoomContainerHeight("53vh");
          } else if (
            aspectRatio < 1.3 &&
            aspectRatio > 0.99 &&
            window.innerWidth < 680
          ) {
            setZoomContainerHeight("40vh");
          } else if (
            aspectRatio < 1.5 &&
            aspectRatio > 1.3 &&
            window.innerWidth < 680
          ) {
            setZoomContainerHeight("40vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 680) {
            setZoomContainerHeight("30vh");
          } else if (aspectRatio > 1.3 && window.innerWidth < 680) {
            setZoomContainerHeight("35vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 680) {
            setZoomContainerHeight("40vh");
          } else if (aspectRatio < 1 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else if (
            aspectRatio < 1.3 &&
            aspectRatio > 0.99 &&
            window.innerWidth < 1024
          ) {
            setZoomContainerHeight("80vh");
          } else if (
            aspectRatio < 1.5 &&
            aspectRatio > 1.3 &&
            window.innerWidth < 1024
          ) {
            setZoomContainerHeight("70vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else if (aspectRatio > 1.3 && window.innerWidth < 1024) {
            setZoomContainerHeight("35vh");
          } else if (aspectRatio > 1.5 && window.innerWidth < 1024) {
            setZoomContainerHeight("80vh");
          } else {
            setZoomContainerHeight("50vh");
          }
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);
  const handleFavoriteToggle = async () => {
    if (!item?._id) return;
    try {
      if (isFavorite) {
        await Api.removeFromFavorites({ id: item._id });
        toast.success(`${item?.name} removed from favorites`);
      } else {
        await Api.addToFavorites({ id: item._id });
        toast.success(`${item?.name} added to favorites`);
      }
      setIsFavorite(!isFavorite);
    } catch (error) {
      console.error("Failed to toggle favorite status", error);
    }
  };
  const toggleDropdown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(prev => !prev);
  };
  async function handleBid(bidPrice, bidCurrencyLocal) {
    setIsDisabled(true);
    try {
      const res = await Api.placeBidByUser(item?._id, {
        itemId: item?._id,
        bidAmount: bidPrice,
        bidCurrency: bidCurrencyLocal,
      });

      if (res.status === 201 || res.status === 200) {
        getItemByID();
        setIsModalOpen(false);

        setUserBid(res?.data?.bid);
        setBidAmount(
          userBid?.isWinning
            ? userBid.originalBidAmount
            : Number(bidOptions[0]),
        );
      } else {
        // Handle error case here if needed
      }
    } catch (error) {
      console.error("Failed to place bid:", error);
    } finally {
      setIsDisabled(false); // Re-enable the button after response
    }
  }

  const handleBidButtonClick = () => {
    if (!user) {
      localStorage.setItem("redirect", `/item/${id}`);
      history.push("/auth/login");
      return;
    }
    if (user.isBannedFromBidding) {
      toast.error("You were banned from bidding. Please contact support.", {
        duration: 6000,
      });
      return;
    }
    if (!user.isPhoneVerified) {
      toast.error("Please verify your phone number to place a bid.", {
        duration: 6000,
      });
      openPhoneModal();
      return;
    }

    if (isNaN(bidAmount)) {
      setBidAmount(Number(bidOptions[0]));
    }
    if (
      convert(minimumBid - 1, item?.currency || "ILS", userCurrency) >=
      bidAmount
    ) {
      toast.error(
        `The minimum bid is ${minimumBid.toFixed(1)} ${userCurrency}`,
      );
      return;
    }

    if (userBid) {
      handleBid(bidAmount, userCurrency);
    } else {
      // Otherwise, open the modal
      setIsModalOpen(true);
    }
  };
  const handleOptionClick = async (e: React.MouseEvent, action: string) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownVisible(false);
    if (action === "Edit Item") {
      if (isAdmin()) {
        history.push(`/admin/auction/manage/${id}`);
      } else {
        history.push(`/auction/manage/${id}`);
      }
    } else if (action === "Delete Item") {
      setIsDeleteModalOpen(true);
    } else if (action === "Hide Item") {
      await handleHideItem();
    } else if (action === "Feature Item") {
      await handleTogglePinned();
    }
  };
  const handleDeleteItem = async () => {
    setIsDeleting(true);
    try {
      if (isAdmin()) {
        const res: any = await Api.deleteAuctionItemAdmin(id);
        if (res.status === 200) {
          setIsDeleteModalOpen(false);
          toast.success("Auction deleted successfully.");
          history.push(`/seller/${user?._id}`);
          toast.success(res.message);
        }
      } else {
        const res = await Api.deleteAuctionItem(item?._id);
        if (res.status === 200) {
          setIsDeleteModalOpen(false);
          toast.success("Auction deleted successfully.");
          history.push("/auctions");
        }
      }
    } catch (error) {
      console.error("Failed to delete item:", error);
      toast.error("Error deleting item.");
    } finally {
      setIsDeleting(false);
    }
  };
  const handleFocus = () => {
    if (!isFocused) {
      setInputValue("");
      setIsFocused(true); // Ensure this happens only once
    }
  };
  const handleBidBlur = () => {
    const bid = inputValue.replace(currencySymbol, "");
    if (minimumBid !== undefined && Number(bid) < minimumBid) {
      toast.error(
        `The minimum bid is ${(minimumBid + 0.01).toFixed(2)} ${userCurrency}`,
      );
      setInputValue(currencySymbol + (minimumBid + 0.01).toFixed(2));
      setBidAmount(Number((minimumBid + 0.01).toFixed(2))); // Set the bid amount to minimumBid if below the minimum
    } else if (minimumBid === undefined) {
      // Optional: handle undefined minimumBid case
      toast.error("Please enter a valid bid amount.");
    } else {
      const parsedValue = Number(parseFloat(bid).toFixed(2)) || 0;
      setInputValue(currencySymbol + parsedValue.toFixed(2));
      setBidAmount(parsedValue);
    }
    setIsFocused(false);
  };
  useEffect(() => {
    if (zoomRef.current && selectedPhoto) {
      const handleImageLoad = () => {
        if (zoomRef.current) {
          setPhotoHeight(zoomRef.current.naturalHeight);
        }
      };
      const imageElement = zoomRef.current;
      imageElement.addEventListener("load", handleImageLoad);
      return () => {
        imageElement.removeEventListener("load", handleImageLoad);
      };
    }
  }, [selectedPhoto]);
  const handleHideItem = async () => {
    try {
      const updatedHiddenStatus = !item?.hidden;
      if (isAdmin()) {
        const res = await Api.updateItemAdmin({
          itemId: item?._id,
          hidden: updatedHiddenStatus,
        });
        if (res.status === 200) {
          setPinned(updatedHiddenStatus);
          if (res.status === 200) {
            setItem({ ...item, hidden: updatedHiddenStatus });
            toast.success(
              updatedHiddenStatus ? "Item hidden" : "Item unhidden",
            );
          }
        }
      } else {
        const res = await Api.updateItem({
          itemId: item?._id,
          hidden: updatedHiddenStatus,
        });
        if (res.status === 200) {
          setItem({ ...item, hidden: updatedHiddenStatus });
          toast.success(updatedHiddenStatus ? "Item hidden" : "Item unhidden");
        }
      }
    } catch (error) {
      console.error("Error hiding item:", error);
      toast.error("Failed to hide/unhide item.");
    }
  };
  const handleTogglePinned = async () => {
    try {
      const updatedPinnedStatus = !pinned;
      setPinned(updatedPinnedStatus);
      const res = await Api.updateItem({
        itemId: item?._id,
        isPinned: updatedPinnedStatus,
      });
      if (res.status === 200) {
        toast.success(updatedPinnedStatus ? "Item pinned" : "Item unpinned");
      }
    } catch (error) {
      console.error("Error toggling pinned status:", error);
      toast.error("Failed to pin/unpin item.");
    }
  };
  async function getSeller(uid: string | undefined) {
    if (!uid) return null;
    try {
      // @ts-expect-error temp
      const res = await Api.getUser(uid._id);
      if (res.status === 200) {
        setSeller(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching user:", error);
    }
    return null;
  }
  const handleImageMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!zoomRef.current || !isZoomedIn) return;
    const { left, top, width, height } =
      zoomRef.current.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;
    const xPercent = (x / width) * 100;
    const yPercent = (y / height) * 100;

    // Constrain xPercent and yPercent between 0 and 100
    const constrainedXPercent = Math.min(100, Math.max(0, xPercent));
    const constrainedYPercent = Math.min(100, Math.max(0, yPercent));

    zoomRef.current.style.transformOrigin = `${constrainedXPercent}% ${constrainedYPercent}%`;
    setZoomOrigin({
      xPercent: constrainedXPercent,
      yPercent: constrainedYPercent,
    });
  };
  const handleMouseEnterSide = (photoUrl: string) => {
    setSelectedPhoto(photoUrl);

    if (lastSelectedPhoto !== photoUrl) {
      setIsZoomedIn(false);
    }
  };

  const handleMouseLeaveSide = () => {
    setSelectedPhoto(lastSelectedPhoto);
  };
  const handleImageClickSidePanel = (photoUrl: string) => {
    if (isImageHandlerOpen) return;
    setLastSelectedPhoto(photoUrl);
    setSelectedPhoto(photoUrl);
    setIsZoomedIn(false);
  };
  const handleImageMouseLeave = () => {
    if (isZoomedIn) {
      //setIsZoomedIn(false);
    }
  };

  const handleImageClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isImageHandlerOpen) return;
    if (isMobile) return;
    if (!zoomRef.current) return;
    const { offsetX, offsetY } = e.nativeEvent;
    const { width, height } = zoomRef.current;
    const xPercent = (offsetX / width) * 100;
    const yPercent = (offsetY / height) * 100;
    if (isZoomedIn) {
      zoomRef.current.style.transformOrigin = "center center";
      setIsZoomedIn(false);
    } else {
      zoomRef.current.style.transformOrigin = `${xPercent}% ${yPercent}%`;
      setZoomOrigin({ xPercent, yPercent });
      setIsZoomedIn(true);
    }
  };
  async function getCategory(categoryId: string | undefined) {
    if (!categoryId) return null;
    try {
      const res = await Api.getCategory(categoryId);
      if (res.status === 200) {
        setCategory(res?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching category:", error);
    }
    return null;
  }
  const openMessageModalOrLogin = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (!user) {
      history.push("/auth/login");
    } else if (user?._id === item?.uid._id && item?.isSold) {
      setMessage(`Hello! Congratulations for winning this auction!`);
      setIsMessageModalOpen(true);
    } else if (userBid?.isWinning && item?.isSold) {
      setMessage(`Hello! I won this item.`);
      setIsMessageModalOpen(true);
    } else if (!item?.isSold) {
      setMessage("I'm interested in this item");
      setIsMessageModalOpen(true);
    } else {
      setMessage("");
      setIsMessageModalOpen(true);
    }
  };
  const verifyCode = async () => {
    const success = await verifyCodeHook();
    if (success) {
      //setIsPhoneVerifiedLocal(true);
    }
  };
  const handleSendMessage = async () => {
    if (!message.trim()) return;
    const msgReceiverId =
      item?.isSold && item?.uid._id == user?._id
        ? item?.winner?._id
        : item?.uid._id;
    const params: any = { receiverId: msgReceiverId, text: message };
    if (includeItemReference) {
      params.itemId = item?._id;
    }
    setIsLoading(true);
    try {
      const res = await Api.sendMessage(params);
      if (res.status === 201) {
        setIsMessageModalOpen(false);
        toast.success("Message sent successfully");
      } else {
        console.error("Failed to send message:", res.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const removeItemReference = () => {
    setIncludeItemReference(false);
    setMessage("");
  };

  const isVisible =
    seller?.visibilitySettings?.itemsVisible === true ||
    user?._id === seller?._id ||
    isAdmin();
  // Prepare SEO meta data using the custom hook
  const seoMeta = useSEO({
    title: item?.name || "Auction Item",
    description:
      item?.description?.substring(0, 150) || "Auction item description",
    image: item?.photos?.[0]?.url || "https://numisnest.com/logo2.png",
    url: `https://numisnest.com/auction/${item?._id}`,
    type: "product",
    locale: "en_US",
    alternateLocale: "he_IL",
  });

  return (
    <>
      <MetaTags meta={seoMeta} />
      {isAdmin() ? <AdminHeader /> : <Header />}
      <main>
        <div className="py-8 sm:py-0 sm:pb-[2rem] md:py-4 bg-primary2">
          <div className="relative mx-auto max-w-[1540px] px-1 w-full">
            {seller && !isMobile && (
              <Link
                to={backButtonLink}
                className="flex ml-5 mb-1.5 mt-[-0.8rem] items-center text-black font-bold text-lg"
              >
                {backButtonText}
                <RiAuctionFill
                  className="ml-2 text-black"
                  size={20}
                />
              </Link>
            )}
            {((user && user?._id === item?.uid._id && !item?.hasBid) ||
              isAdmin()) && (
              //back to auction

              <div
                className="self-start ml-3 mb-4 max-w-[90px]"
                ref={dropdownRef}
              >
                <Button
                  onClick={toggleDropdown}
                  className="flex items-center gap-1 px-2 py-1 bg-blue-600 text-white !text-sm rounded"
                >
                  <FaUserCog size={14} /> Manage
                </Button>
                {dropdownVisible && (
                  <div className="absolute bg-white border border-gray-300 rounded-md shadow-lg mt-2 z-50">
                    <button
                      onClick={e => handleOptionClick(e, "Edit Item")}
                      className="block px-4 py-2 text-sm  lg:min-w-[150px] lg:text-lg  text-black hover:bg-gray-100 w-full text-left"
                    >
                      Edit
                    </button>

                    <button
                      onClick={e => handleOptionClick(e, "Delete Item")}
                      className="block px-4 py-2 text-sm text-black lg:text-lg  hover:bg-gray-100 w-full text-left"
                    >
                      Delete
                    </button>
                  </div>
                )}
              </div>
            )}
            <div className="relative mx-auto max-w-[1560px] sm:px-0 lg:px-6 w-full">
              <div className="flex flex-wrap max-lg:gap-2">
                <div className="w-full lg:w-[55%]  lg:pr-4">
                  <div
                    style={{
                      minHeight:
                        window.innerWidth < 1024
                          ? "calc(100vw - 20px) "
                          : "5px",
                    }}
                    className="flex flex-wrap-reverse w-full sm:mt-0 gap-1  max-lg:h-full lg:h-full lg:max-h-[90vh]"
                  >
                    <div
                      className={`grid  gap-2 scrollbar-thumb-gray-500 scrollbar-track-gray-300 scrollbar-thin lg:overflow-y-auto max-lg:overflow-x-auto lg:h-full ${
                        (item?.photos?.length || 0) + (item?.video ? 1 : 0) > 7
                          ? "grid-cols-1 max-h-[90vh]"
                          : "grid-cols-1"
                      }`}
                    >
                      {" "}
                      <div className="flex gap-2 lg:gap-0 scrollable sm:px-1 lg:flex-col lg:h-full">
                        {item?.photos?.map((photo, idx) => (
                          <div
                            key={idx}
                            className="bg-white lg:border-4 lg:border-transparent min-w-[5.5rem] min-h-[5.5rem]  max-w-[5.5rem] max-h-[5.5rem] md:min-h-[10rem] md:min-w-[10rem] lg:min-h-[5.5rem] lg:min-w-[5.5rem] overflow-hidden rounded-md border border-primary cursor-pointer"
                            onMouseEnter={() => handleMouseEnterSide(photo.url)}
                            onMouseLeave={handleMouseLeaveSide}
                            onClick={() => handleImageClickSidePanel(photo.url)}
                          >
                            <img
                              src={photo.url}
                              alt=""
                              className="size-full object-contain"
                            />
                          </div>
                        ))}
                        {item?.video && (
                          <div
                            className="bg-white lg:border-4 lg:border-transparent min-w-[5.5rem] min-h-[5.5rem] max-w-[5.5rem] max-h-[5.5rem] overflow-hidden rounded-md border border-primary cursor-pointer"
                            onMouseEnter={() =>
                              handleMouseEnterSide(item.video)
                            }
                            onMouseLeave={handleMouseLeaveSide}
                            onClick={() =>
                              handleImageClickSidePanel(item.video)
                            }
                          >
                            <video
                              src={item.video}
                              className="size-full object-contain "
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div
                      className={`p-0 w-full lg:w-[calc(100%_-_120px)]  flex justify-center  ${user && user?._id === item?.uid._id && !item?.hasBid ? "mt-[-2.8rem]" : ""} `}
                      style={{
                        minHeight: imageHeight,
                      }}
                    >
                      <ImageHandler
                        photos={item?.photos?.map(photo => photo.url) || []}
                        selectedPhoto={selectedPhoto}
                        setSelectedPhoto={setSelectedPhoto}
                        onEnlargedChange={setIsImageHandlerOpen}
                        imageSize={imageSize}
                        isSold={item?.isSold}
                      >
                        <div
                          className="zoom-container "
                          ref={imgRef}
                          onMouseMove={handleImageMouseMove}
                          onClick={handleImageClick}
                          onMouseLeave={handleImageMouseLeave}
                          style={{
                            minHeight: isZoomedIn
                              ? "auto"
                              : zoomContainerHeight,
                            display: "flex", // Ensures flexbox layout
                            // Centers the image vertically
                            justifyContent: "center",
                            position: "relative",
                          }}
                        >
                          {isVideo(selectedPhoto) ? (
                            <video
                              controls
                              className="zoomed-video"
                              style={{
                                display: "block",
                                minHeight: "50vh",
                                maxHeight: isMobile ? "87vh" : "90vh",
                                objectFit: "contain",
                              }}
                              src={selectedPhoto}
                            />
                          ) : (
                            <img
                              ref={zoomRef}
                              src={
                                selectedPhoto ||
                                "https://via.placeholder.com/150"
                              }
                              alt="Main"
                              className={`zoomed-image ${isZoomedIn ? "zoomed-in" : ""}`}
                              style={{
                                display: "block",
                                maxHeight: isMobile ? "87vh" : "90vh",
                                marginTop:
                                  window.innerWidth < 1024 ? "0rem" : "0rem",
                                marginBottom:
                                  window.innerWidth < 1024 ? "0rem" : "0",
                                cursor: isZoomedIn ? "zoom-out" : "zoom-in",
                                objectFit: isZoomedIn ? "cover" : "contain",
                                objectPosition:
                                  window.innerWidth < 1024 ? "top" : "top",
                              }}
                            />
                          )}
                        </div>
                      </ImageHandler>
                    </div>
                  </div>
                </div>
                <div
                  className={`w-full p-0 sm:px-1 lg:py-2  lg:w-[45%] lg:pl-4  ${user && user?._id === item?.uid._id && !item?.hasBid ? "mt-[-2.8rem]" : ""} `}
                >
                  <h2 className="text-xl md:text-2xl font-semibold mb-4">
                    {item?.name || "?"}
                  </h2>

                  <hr className="bg-black h-0.5" />
                  {item?.uid !== user?._id ||
                    (remainingTime <= 0 && item !== null && (
                      <div className="p-1.5 rounded-lg bg-white text-primary font-semibold text-xl w-fit">
                        {convert(customPrice, customCurrency, userCurrency)
                          ? `${convert(customPrice, customCurrency, userCurrency)} ${userCurrency}`
                          : "No price available"}
                      </div>
                    ))}

                  {item?.uid?.id !== user?._id &&
                    (isDisabled ? (
                      // Render "Loading..." when isDisabled is true
                      <div className="mt-1 mb-[-1rem]">
                        <p className="text-gray-500 font-semibold">
                          Loading...
                        </p>
                      </div>
                    ) : (
                      <>
                        {/* Bid section */}
                        <div
                          className={`flex flex-row md:flex-row items-center max-md:justify-center ${
                            isCustomBid ? "" : "justify-between"
                          } mt-3 space-y-0 md:space-y-0`}
                        >
                          {item?.price &&
                            remainingTime > 0 &&
                            !item?.isSold && (
                              <>
                                <select
                                  value={bidAmount}
                                  onChange={e => {
                                    setBidAmount(Number(e.target.value));
                                    // Blur the select element to close the dropdown
                                    e.target.blur();
                                  }}
                                  className="p-2 border border-gray-300 rounded-md"
                                >
                                  {generateBidOptions(minimumBid).map(
                                    option => (
                                      <option
                                        key={option}
                                        value={option}
                                      >
                                        {`${option} ${userCurrency}`}
                                      </option>
                                    ),
                                  )}
                                </select>

                                <button
                                  onClick={handleBidButtonClick}
                                  className="py-2 px-4 min-w-[100px] bg-primary text-white rounded-md font-semibold ml-2"
                                >
                                  Bid
                                </button>
                              </>
                            )}
                        </div>
                        {/* User bid information */}
                        {user && userBid && (
                          <div className="mt-1 mb-[-0.5rem] ">
                            {!item?.remainingTime ? (
                              userBid.isWinning ? (
                                <div>
                                  <p className="lg:mt-[-0.8rem] text-2xl font-semibold">
                                    <span className="text-green-600 ">
                                      {" "}
                                      Seller:{" "}
                                    </span>
                                    {item?.uid?.fname && item?.uid?.lname
                                      ? `${item.uid.fname} ${item.uid.lname}`
                                      : "Unknown Seller"}
                                  </p>{" "}
                                  <p className="lg:mt-[0rem] text-xl font-semibold">
                                    <span className=""> Email: </span>
                                    {item?.uid?.email ? (
                                      <a
                                        href={`mailto:${item?.uid?.email}`}
                                        className="text-blue-500 underline hover:text-blue-700"
                                      >
                                        {item?.uid?.email}
                                      </a>
                                    ) : (
                                      "Unknown Email"
                                    )}
                                  </p>
                                  <p className="lg:mt-[0rem] text-xl font-semibold">
                                    <span>Phone: </span>
                                    {item?.uid?.phone ? (
                                      <a
                                        href={`tel:${item.uid.phone}`}
                                        className="text-blue-500 underline hover:text-blue-700"
                                      >
                                        +{item.uid.phone}
                                      </a>
                                    ) : (
                                      "Unknown Phone Number"
                                    )}
                                  </p>
                                  <Button
                                    className="!w-fit mt-4 text-lg font-semibold  max-h-[80px] min-w-[120px]"
                                    onClick={openMessageModalOrLogin}
                                  >
                                    {`Message ${item?.uid?.fname}`}
                                  </Button>
                                </div>
                              ) : (
                                <p className="text-red-500 lg:mt-[-0.8rem] mb-[0.5rem] font-semibold">
                                  You were outbid
                                </p>
                              )
                            ) : userBid.isWinning ? (
                              <p className=" text-primary text-sm font-semibold">
                                Your maximum bid is: {userBid.originalBidAmount}{" "}
                                {userBid.originalCurrency}
                              </p>
                            ) : (
                              <p
                                className={`text-red-500 ${userBid ? "" : "hidden"}  lg:mt-[-0.3rem]  font-semibold`}
                              >
                                You were outbid
                              </p>
                            )}
                          </div>
                        )}
                      </>
                    ))}
                  {user &&
                    user._id &&
                    user._id == item?.uid._id &&
                    item?.isSold && (
                      <div className="mt-6 mb-[-0.5rem]">
                        <p className="lg:mt-[-0.8rem] text-2xl font-semibold">
                          <span className="text-green-600"> Winner: </span>
                          {item?.winner?.fname && item?.winner?.lname
                            ? `${item.winner.fname} ${item.winner.lname}`
                            : "No winner yet"}
                        </p>
                        <p className="lg:mt-[0rem] text-xl font-semibold">
                          <span className=""> Email: </span>
                          {item?.winner?.email ? (
                            <a
                              href={`mailto:${item.winner.email}`}
                              className="text-blue-500 underline hover:text-blue-700"
                            >
                              {item?.winner?.email}
                            </a>
                          ) : (
                            "Unknown Email"
                          )}
                        </p>
                        <p className="lg:mt-[0rem] text-xl font-semibold">
                          <span>Phone: </span>
                          {item?.winner?.phone ? (
                            <a
                              href={`tel:${item.winner.phone}`}
                              className="text-blue-500 underline hover:text-blue-700"
                            >
                              +{item.winner.phone}
                            </a>
                          ) : (
                            "Unknown Phone Number"
                          )}
                        </p>
                        <Button
                          className="!w-fit mt-4 text-lg font-semibold max-h-[80px] min-w-[120px]"
                          onClick={openMessageModalOrLogin}
                        >
                          {`Message ${item?.winner?.fname}`}
                        </Button>
                      </div>
                    )}
                  <br />
                  {remainingTime > 0 && (
                    <CountdownProps remainingTime={remainingTime} />
                  )}

                  {remainingTime <= 0 &&
                    item !== null &&
                    !userBid?.isWinning &&
                    user?._id !== item?.uid?._id && (
                      <p className="text-lg mt-[-2rem] font-semibold text-red-500">
                        Auction ended
                      </p>
                    )}
                  <div className="rounded-lg  mt-4 text-lg w-fit">
                    {convert(
                      item?.initialPrice || 0,
                      customCurrency,
                      userCurrency,
                    )
                      ? `Start price: ${convert(item?.initialPrice || 0, item?.currency || "ILS", userCurrency)} ${userCurrency}`
                      : "No price available"}
                  </div>
                  {userBid?.isWinning && item?.isSold && (
                    <div className="p-1.5 mt-[0rem] rounded-lg bg-green-600 text-white font-semibold text-xl w-fit">
                      {`Purchased for: ${convert(item?.price || 0, item?.currency || "ILS", userCurrency)} ${userCurrency}`}
                    </div>
                  )}
                  {!userBid?.isWinning && item?.isSold && (
                    <div className="p-1.5 mt-[0rem] rounded-lg bg-green-600 text-white font-semibold text-xl w-fit">
                      {`Sold for: ${Number(item?.price) > 100 ? item?.price?.toFixed(0) : item?.price?.toFixed(2)} ${item?.currency}`}
                    </div>
                  )}
                  {user &&
                    user._id &&
                    user._id == item?.uid._id &&
                    item?.isSold && (
                      <p className="text-lg mt-[-1.5rem] text-right  font-semibold">
                        {(() => {
                          const createdAt = new Date(item?.createdAt || 0);
                          const endDate = new Date(
                            createdAt.getTime() +
                              (item?.duration || 0) * 24 * 60 * 60 * 1000,
                          );
                          const formattedDate = `${endDate.getDate().toString().padStart(2, "0")}/${(
                            endDate.getMonth() + 1
                          )
                            .toString()
                            .padStart(
                              2,
                              "0",
                            )} at ${endDate.getHours().toString().padStart(2, "0")}:${endDate
                            .getMinutes()
                            .toString()
                            .padStart(2, "0")}`;
                          return formattedDate;
                        })()}
                      </p>
                    )}
                  {userBid?.isWinning && item?.isSold && (
                    <p className="text-lg mt-[-1.5rem] text-right font-semibold">
                      {(() => {
                        const createdAt = new Date(item?.createdAt || 0);
                        const endDate = new Date(
                          createdAt.getTime() +
                            (item?.duration || 0) * 24 * 60 * 60 * 1000,
                        );
                        const formattedDate = `${endDate.getDate().toString().padStart(2, "0")}/${(
                          endDate.getMonth() + 1
                        )
                          .toString()
                          .padStart(
                            2,
                            "0",
                          )} at ${endDate.getHours().toString().padStart(2, "0")}:${endDate
                          .getMinutes()
                          .toString()
                          .padStart(2, "0")}`;
                        return formattedDate;
                      })()}
                    </p>
                  )}
                  {item?.hasBid && !item?.isSold && userBid?.isWinning && (
                    <div className="p-1.5 mt-[0rem] rounded-lg bg-green-600 text-white font-semibold text-xl w-fit">
                      {`You leading bid: ${convert(item?.price || 0, item?.currency || "ILS", userCurrency)} ${userCurrency}`}
                    </div>
                  )}
                  {item?.hasBid &&
                    !item?.isSold &&
                    !userBid?.isWinning &&
                    userBid !== null && (
                      <div className="p-1.5 mt-[0rem] rounded-lg bg-red-600 text-white font-semibold text-xl w-fit">
                        {`Leading bid: ${convert(item?.price || 0, item?.currency || "ILS", userCurrency)} ${userCurrency}`}
                      </div>
                    )}
                  {item?.hasBid && !item?.isSold && userBid == null && (
                    <div className="p-1.5 mt-[0rem] rounded-lg bg-primary text-white font-semibold text-xl w-fit">
                      {`Leading bid: ${convert(item?.price || 0, item?.currency || "ILS", userCurrency)} ${userCurrency}`}
                    </div>
                  )}

                  {isMobile && !item?.isSold && (
                    <div className="relative flex justify-end inline-block">
                      <button
                        onClick={handleShare}
                        className="flex items-center mt-[-2.4rem] px-4 py-1 bg-blue-600 text-white rounded-lg font-semibold text-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
                        aria-label="Share this item"
                      >
                        <FaShareFromSquare
                          fill="#fff"
                          className="w-5 h-5 mr-2"
                        />
                        Share
                      </button>
                    </div>
                  )}
                  <div className="bg-white whitespace-pre-wrap rounded-lg mt-2 border border-[#00000099] p-2.5 max-md:text-sm break-words">
                    {item?.description || `No description available`}
                  </div>
                  <div className="mt-2 flex items-center gap-3 flex-wrap text-xs md:text-sm">
                    <p>
                      Countries:{" "}
                      {item?.countries?.map((country, idx) => (
                        <span key={idx}>
                          {country}
                          {idx < (item.countries?.length ?? 0) - 1 ? ", " : ""}
                        </span>
                      )) || "-"}
                    </p>
                    <p>
                      Categories:{" "}
                      {item?.categories?.map((categ, idx) => (
                        <span key={idx}>
                          {categ.name}
                          {idx < (item.categories?.length ?? 0) - 1 ? ", " : ""}
                        </span>
                      )) || "-"}
                    </p>
                    <p>Year: {item?.years?.join(", ") || "Not applicable"}</p>
                  </div>
                  {userAuctionsCount > 1 && seller?.fname && (
                    <Link
                      to={`/seller/${seller?._id}/auctions`}
                      className="mt-1 inline-block px-4 py-2 text-white bg-orange-500 rounded-md text-center font-semibold"
                    >
                      {`See all ${seller?.fname}'s auctioned items`}
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <br />
            <div className="md:mt-4 px-3 flex max-md:gap-4 flex-wrap-reverse">
              <div className="w-full md:w-[55%] md:pr-8">
                <p className="md:text-lg mt-3 font-semibold mb-1">Seller</p>

                <div className="bg-white p-3 md:p-4 rounded-2xl border border-black max-w-[500px]">
                  <Link to={`/seller/${seller?._id}`}>
                    <div className="flex items-center gap-1 sm:gap-1">
                      <img
                        src={seller?.avatar || avatar}
                        alt="Seller"
                        className="size-14 object-cover"
                      />
                      <div className="flex flex-grow items-center justify-between w-full">
                        <div className="flex-grow">
                          <div className="flex items-center">
                            <p
                              style={{
                                wordBreak: "break-all",
                                overflowWrap: "break-word",
                                whiteSpace: "normal",
                              }}
                              className="text-md sm:text-sm md:text-xl line-clamp-1 font-semibold mb-1 sm:mr-[-0.8rem] md:mb-2"
                            >
                              {seller?.fname} {seller?.lname}
                            </p>
                            {seller?.topRated && (
                              <div
                                className="relative ml-5"
                                onMouseEnter={() => setHoveredTopRated(true)}
                                onMouseLeave={() => setHoveredTopRated(null)}
                              >
                                <img
                                  className="w-[10px] sm:max-w-[15px] md:min-w-[25px]"
                                  src={topRatedBadge}
                                  alt={`Top Rated Seller`}
                                />

                                {/* Tooltip */}
                                {hoveredTopRated && (
                                  <div className="absolute whitespace-nowrap bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white  rounded-md px-2 py-1 z-10">
                                    {"Top Rated Seller"}
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                          <p
                            className="max-md:text-sm sm:text-sm lg:text-sm overflow-hidden  sm:mr-[-0.8rem]  text-ellipsis"
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                            }}
                          >
                            {seller?.about}
                          </p>
                        </div>
                        {user?._id !== seller?._id &&
                          seller?.visibilitySettings?.messagingVisible && (
                            <Button
                              className="!w-fit ml-3 max-h-[40px] min-w-[120px]"
                              onClick={openMessageModalOrLogin}
                            >
                              Message
                            </Button>
                          )}
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="w-full md:w-[45%] md:pl-4 md:mt-9">
                <div className="bg-[#85858530] rounded-2xl p-4">
                  <div className="flex items-center gap-2 mb-2">
                    <img
                      src={truck}
                      className="size-6"
                      alt="Purchase & delivery"
                    />
                    <span className="font-semibold max-md:text-sm">
                      Purchase & delivery
                    </span>
                  </div>
                  <p
                    dir="auto"
                    className="max-md:text-sm whitespace-pre-wrap line-clamp-3 break-words max-w-[80vw]"
                  >
                    {item && seller ? (
                      item?.deliveryOption || seller?.deliveryOption ? (
                        <>{item.deliveryOption || seller.deliveryOption}</>
                      ) : (
                        "No delivery option available"
                      )
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
              </div>
            </div>
            <br />
            <br />
            {similarItems.length > 0 && (
              <div>
                <p className="text-2xl md:text-3xl font-bold mb-4">
                  Similar Items
                </p>
                <div className="p-0 md:p-5 rounded-2xl bg-[#D8E8FF]">
                  <div className="grid gap-2 md:gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                    {similarItems.map((iter, idx) => (
                      <Item
                        key={iter._id}
                        isPinned={iter.isPinned}
                        isFeatured={iter.isFeatured}
                        isCountry={iter.isCountry}
                        isSeller={iter.isSeller}
                        name={iter.name}
                        user_data={iter.uid}
                        uid={iter.uid._id}
                        description={iter.description}
                        country={iter.country}
                        photos={iter.photos}
                        currency={userCurrency}
                        price={
                          iter?.price && iter?.currency
                            ? convert(iter?.price, iter?.currency, userCurrency)
                            : 0
                        }
                        year={iter.year}
                        years={iter.years}
                        id={iter._id}
                        hidden={iter.hidden}
                        category={iter.categories}
                        createdAt={iter.createdAt}
                        updatedAt={iter.updatedAt}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <BidOrBuyModal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        item={item}
        bidAmount={bidAmount}
        handleBid={handleBid}
        bidCurrency={userCurrency}
        isDisabled={isDisabled}
      />
      <Modal
        isOpen={isMessageModalOpen}
        onRequestClose={() => setIsMessageModalOpen(false)}
        contentLabel="Send Message"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto md:min-w-[40%] mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="mb-[0.5rem]">
          Send a Message to {seller?.fname || "Seller"}
        </h2>
        {}
        {includeItemReference && (
          <div className="flex items-center gap-1 mb-4 relative">
            <img
              src={item?.photos?.[0]?.url || "https://via.placeholder.com/150"}
              alt={item?.name}
              className="w-20 h-20 object-cover"
            />
            <div>
              <p className="font-semibold">{item?.name}</p>
              <p className="text-sm line-clamp-2">{item?.description}</p>
            </div>
            {}
            <button
              onClick={removeItemReference}
              className="absolute top-0 right-1 p-1 text-gray-500 hover:text-red-500"
            >
              <FaTimes />
            </button>
          </div>
        )}
        <textarea
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Type your message here..."
          className="w-full p-2 border border-gray-300 rounded"
          rows={5}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={() => {
              setIsMessageModalOpen(false), setIncludeItemReference(true);
            }}
            className="bg-gray-300 text-gray-700 px-4 py-2 rounded mr-2"
          >
            Cancel
          </button>
          <button
            onClick={handleSendMessage}
            className="bg-blue-500 text-white px-4 py-2 rounded"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send"}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Item"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
        <p>
          Are you sure you want to delete this item? This action cannot be
          undone.
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <Button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200"
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteItem}
            className="px-4 py-2 bg-red-500 text-white"
            disabled={isDeleting}
          >
            {isDeleting ? (
              <FaSpinner className="animate-spin mr-2" />
            ) : (
              "Yes, Delete"
            )}
          </Button>
        </div>
      </Modal>
      <PhoneVerificationModals
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        otpCode={otpCode}
        setOtpCode={setOtpCode}
        isPhoneModalOpen={isPhoneModalOpen}
        isOtpModalOpen={isOtpModalOpen}
        closePhoneModal={closePhoneModal}
        closeOtpModal={closeOtpModal}
        sendVerification={sendVerification}
        verifyCode={verifyCode}
        loading={loading}
      />
      <Footer />
    </>
  );
};
export default AuctionItem;

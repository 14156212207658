import { FaPhone } from "react-icons/fa6";
import { IoMdContacts } from "react-icons/io";
import { IoFlash, IoHome, IoLayersSharp, IoLibrary } from "react-icons/io5";
import { RiAuctionFill } from "react-icons/ri";

export const REACT_APP_KEY_NAME = "numisnest-key";

export const LOADER_OVERRIDE = {
  display: "block",
  margin: "0 auto",
};
export const englishKeywords = [
  "Collectibles",
  "Antique collections",
  "Rare items",
  "Vintage collections",
  "Collector's items",
  "Numismatics",
  "Rare coins",
  "Stamp collecting",
  "Historical artifacts",
  "Online collectibles",
  "Collector's market",
  "Commemorative medals",
  "Historical banknotes",
  "World currency",
  "Buy collectibles online",
  "Coin auctions",
  "Stamp grading",
  "Banknote auctions",
  "Numismatic coins",
  "Currency history",
  "Collector's community",
];

export const hebrewKeywords = [
  "אספנות",
  "פרטי אספנות",
  "מטבעות נדירים",
  "בולים נדירים",
  "עתיקות",
  "אוספים נדירים",
  "היסטוריה של מטבעות",
  "שטרות נדירים",
  "נומיסמטיקה",
  "רכישת פריטי אספנות באינטרנט",
];
export const allKeywords = [...englishKeywords, ...hebrewKeywords].join(", ");
export const europeanCountries = [
  "AL", // Albania
  "AD", // Andorra
  "AM", // Armenia
  "AT", // Austria
  "AZ", // Azerbaijan
  "BY", // Belarus
  "BE", // Belgium
  "BA", // Bosnia and Herzegovina
  "BG", // Bulgaria
  "HR", // Croatia
  "CY", // Cyprus
  "CZ", // Czechia
  "DK", // Denmark
  "EE", // Estonia
  "FI", // Finland
  "FR", // France
  "GE", // Georgia
  "DE", // Germany
  "GR", // Greece
  "HU", // Hungary
  "IS", // Iceland
  "IE", // Ireland
  "IT", // Italy
  "KZ", // Kazakhstan
  "XK", // Kosovo
  "LV", // Latvia
  "LI", // Liechtenstein
  "LT", // Lithuania
  "LU", // Luxembourg
  "MT", // Malta
  "MD", // Moldova
  "MC", // Monaco
  "ME", // Montenegro
  "NL", // Netherlands
  "MK", // North Macedonia
  "NO", // Norway
  "PL", // Poland
  "PT", // Portugal
  "RO", // Romania
  "RU", // Russia
  "SM", // San Marino
  "RS", // Serbia
  "SK", // Slovakia
  "SI", // Slovenia
  "ES", // Spain
  "SE", // Sweden
  "CH", // Switzerland
  "TR", // Turkey
  "UA", // Ukraine
  "GB", // United Kingdom
  "VA", // Vatican City
];
export const SELECT_STYLE = {
  control: (provided: any) => ({
    ...provided,
    outline: "none",
    border: 0,
    padding: "4.5px !important",
    backgroundColor: "#efefef",
    borderRadius: "var(--border_radius) !important",
    marginBottom: "0.8rem",
  }),
};

export const ROLES = {
  ADMIN: 0,
  USER: 1,
};

export const ROLES_MAP = {
  0: "Admin",
  1: "User",
};

export const LINKS = [
  {
    title: "Home",
    path: "/",
    icon: IoHome,
  },
  {
    title: "Contact Us",
    path: "/contact-us",
    icon: FaPhone,
  },
  {
    title: "Blog",
    path: "/blog",
    icon: IoFlash,
  },
  {
    title: "About Us",
    path: "/about-us",
    icon: IoLibrary,
  },
  /*  {
    title: "Auctions",
    path: "/auctions",
    icon: RiAuctionFill,
  },*/
];
export const ADMIN_LINKS = [
  {
    title: "Dashboard",
    path: "/admin/dashboard",
    icon: IoLibrary,
  },
  {
    title: "Items",
    path: "/admin/items",
    icon: IoLibrary,
  },
  {
    title: "Auctions",
    path: "/admin/auctions",
    icon: IoLibrary,
  },
  {
    title: "Users",
    path: "/admin/users",
    icon: IoMdContacts,
  },
  {
    title: "Collections",
    path: "/admin/collections",
    icon: IoLayersSharp,
  },
  {
    title: "Blog",
    path: "/admin/blog",
    icon: IoFlash,
  },
  {
    title: "Categories",
    path: "/admin/categories",
    icon: IoFlash,
  },
  {
    title: "All messages",
    path: "/admin/all-messages",
    icon: IoFlash,
  },
];
export const countries = [
  { value: "AF", label: "Afghanistan" },
  { value: "AL", label: "Albania" },
  { value: "DZ", label: "Algeria" },
  { value: "AD", label: "Andorra" },
  { value: "AO", label: "Angola" },
  { value: "AG", label: "Antigua and Barbuda" },
  { value: "AR", label: "Argentina" },
  { value: "AM", label: "Armenia" },
  { value: "AU", label: "Australia" },
  { value: "AD_I", label: "Alderney" },
  { value: "EC_O", label: "East Caribbean" },
  { value: "AT", label: "Austria" },
  { value: "AZ", label: "Azerbaijan" },
  { value: "BS", label: "Bahamas" },
  { value: "BH", label: "Bahrain" },
  { value: "BD", label: "Bangladesh" },
  { value: "BB", label: "Barbados" },
  { value: "BY", label: "Belarus" },
  { value: "BE", label: "Belgium" },
  { value: "BZ", label: "Belize" },
  { value: "BJ", label: "Benin" },
  { value: "BT", label: "Bhutan" },
  { value: "BO", label: "Bolivia" },
  { value: "BA", label: "Bosnia and Herzegovina" },
  { value: "BW", label: "Botswana" },
  { value: "BR", label: "Brazil" },
  { value: "BN", label: "Brunei" },
  { value: "BG", label: "Bulgaria" },
  { value: "BF", label: "Burkina Faso" },
  { value: "BI", label: "Burundi" },
  { value: "CV", label: "Cabo Verde" },
  { value: "KH", label: "Cambodia" },
  { value: "CM", label: "Cameroon" },
  { value: "CI_I", label: "Cook Island" },
  { value: "CA", label: "Canada" },
  { value: "CF", label: "Central African Republic" },
  { value: "TD", label: "Chad" },
  { value: "CL", label: "Chile" },
  { value: "CN", label: "China" },
  { value: "CO", label: "Colombia" },
  { value: "KM", label: "Comoros" },
  { value: "CG", label: "Congo" },
  { value: "CD", label: "Congo (DRC)" },
  { value: "CR", label: "Costa Rica" },
  { value: "HR", label: "Croatia" },
  { value: "CU", label: "Cuba" },
  { value: "CY", label: "Cyprus" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DJ", label: "Djibouti" },
  { value: "DM", label: "Dominica" },
  { value: "DO", label: "Dominican Republic" },
  { value: "EC", label: "Ecuador" },
  { value: "EG", label: "Egypt" },
  { value: "SV", label: "El Salvador" },
  { value: "GQ", label: "Equatorial Guinea" },
  { value: "ER", label: "Eritrea" },
  { value: "EE", label: "Estonia" },
  { value: "SZ", label: "Eswatini" },
  { value: "ET", label: "Ethiopia" },
  { value: "FJ", label: "Fiji" },
  { value: "FI", label: "Finland" },
  { value: "FR", label: "France" },
  { value: "GA", label: "Gabon" },
  { value: "GM", label: "Gambia" },
  { value: "GE", label: "Georgia" },
  { value: "DE", label: "Germany" },
  { value: "GH", label: "Ghana" },
  { value: "GR", label: "Greece" },
  { value: "GD", label: "Grenada" },
  { value: "GT", label: "Guatemala" },
  { value: "GN", label: "Guinea" },
  { value: "GW", label: "Guinea-Bissau" },
  { value: "GY", label: "Guyana" },
  { value: "HT", label: "Haiti" },
  { value: "HN", label: "Honduras" },
  { value: "HU", label: "Hungary" },
  { value: "IS", label: "Iceland" },
  { value: "IN", label: "India" },
  { value: "ID", label: "Indonesia" },
  { value: "IR", label: "Iran" },
  { value: "IQ", label: "Iraq" },
  { value: "IE", label: "Ireland" },
  { value: "IL", label: "Israel" },
  { value: "IT", label: "Italy" },
  { value: "JM", label: "Jamaica" },
  { value: "JP", label: "Japan" },
  { value: "JO", label: "Jordan" },
  { value: "KZ", label: "Kazakhstan" },
  { value: "KE", label: "Kenya" },
  { value: "KI", label: "Kiribati" },
  { value: "KP", label: "North Korea" },
  { value: "KR", label: "South Korea" },
  { value: "KW", label: "Kuwait" },
  { value: "KG", label: "Kyrgyzstan" },
  { value: "LA", label: "Laos" },
  { value: "LV", label: "Latvia" },
  { value: "LB", label: "Lebanon" },
  { value: "LS", label: "Lesotho" },
  { value: "LR", label: "Liberia" },
  { value: "LY", label: "Libya" },
  { value: "LI", label: "Liechtenstein" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "MG", label: "Madagascar" },
  { value: "MW", label: "Malawi" },
  { value: "MY", label: "Malaysia" },
  { value: "MV", label: "Maldives" },
  { value: "ML", label: "Mali" },
  { value: "MT", label: "Malta" },

  { value: "MH", label: "Marshall Islands" },
  { value: "MR", label: "Mauritania" },
  { value: "MU", label: "Mauritius" },
  { value: "MX", label: "Mexico" },
  { value: "FM", label: "Micronesia" },
  { value: "MD", label: "Moldova" },
  { value: "MC", label: "Monaco" },
  { value: "MN", label: "Mongolia" },
  { value: "ME", label: "Montenegro" },
  { value: "MA", label: "Morocco" },
  { value: "MZ", label: "Mozambique" },
  { value: "MM", label: "Myanmar" },
  { value: "NA", label: "Namibia" },
  { value: "NR", label: "Nauru" },
  { value: "NP", label: "Nepal" },
  { value: "NL", label: "Netherlands" },
  { value: "NZ", label: "New Zealand" },
  { value: "NI", label: "Nicaragua" },
  { value: "NE", label: "Niger" },
  { value: "NG", label: "Nigeria" },
  { value: "MK", label: "North Macedonia" },
  { value: "NO", label: "Norway" },
  { value: "OM", label: "Oman" },
  { value: "PK", label: "Pakistan" },
  { value: "PW", label: "Palau" },
  { value: "PS", label: "Palestine" },
  { value: "PA", label: "Panama" },
  { value: "PG", label: "Papua New Guinea" },
  { value: "PY", label: "Paraguay" },
  { value: "PE", label: "Peru" },
  { value: "PH", label: "Philippines" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "QA", label: "Qatar" },
  { value: "RO", label: "Romania" },
  { value: "RU", label: "Russia" },
  { value: "RW", label: "Rwanda" },
  { value: "KN", label: "Saint Kitts and Nevis" },
  { value: "LC", label: "St. Lucia" },
  { value: "VC", label: "Saint Vincent and the Grenadines" },
  { value: "WS", label: "Samoa" },
  { value: "SM", label: "San Marino" },
  { value: "ST", label: "Sao Tome and Principe" },
  { value: "SA", label: "Saudi Arabia" },
  { value: "SN", label: "Senegal" },
  { value: "RS", label: "Serbia" },
  { value: "SC", label: "Seychelles" },
  { value: "SL", label: "Sierra Leone" },
  { value: "SG", label: "Singapore" },
  { value: "SK", label: "Slovakia" },
  { value: "SI", label: "Slovenia" },
  { value: "SB", label: "Solomon Islands" },
  { value: "SO", label: "Somalia" },
  { value: "ZA", label: "South Africa" },

  { value: "SS", label: "South Sudan" },
  { value: "ES", label: "Spain" },
  { value: "LK", label: "Sri Lanka" },
  { value: "SD", label: "Sudan" },
  { value: "SR", label: "Suriname" },
  { value: "SE", label: "Sweden" },
  { value: "CH", label: "Switzerland" },
  { value: "SY", label: "Syria" },
  { value: "TW", label: "Taiwan" },
  { value: "TJ", label: "Tajikistan" },
  { value: "TZ", label: "Tanzania" },
  { value: "TH", label: "Thailand" },
  { value: "TG", label: "Togo" },
  { value: "TO", label: "Tonga" },
  { value: "TT", label: "Trinidad and Tobago" },
  { value: "TN", label: "Tunisia" },
  { value: "TR", label: "Turkey" },
  { value: "TM", label: "Turkmenistan" },
  { value: "TV", label: "Tuvalu" },
  { value: "UG", label: "Uganda" },
  { value: "UA", label: "Ukraine" },
  { value: "AE", label: "United Arab Emirates" },
  { value: "GB", label: "United Kingdom (Britain)" },
  { value: "US", label: "United States of America" },
  { value: "UY", label: "Uruguay" },
  { value: "UZ", label: "Uzbekistan" },
  { value: "VU", label: "Vanuatu" },
  { value: "VA", label: "Vatican City" },
  { value: "VE", label: "Venezuela" },
  { value: "VN", label: "Vietnam" },
  { value: "YE", label: "Yemen" },
  { value: "ZM", label: "Zambia" },
  { value: "ZW", label: "Zimbabwe" },
];

export const historicalCountries = [
  { value: "AH_E", label: "Austro-Hungarian Empire" },
  { value: "OE_E", label: "Ottoman Empire" },
  { value: "BY_E", label: "Byzantine Empire" },
  { value: "PR_E", label: "Prussia" },
  { value: "YU_E", label: "Yugoslavia" },
  { value: "CS_E", label: "Czechoslovakia" },
  { value: "HR_E", label: "Holy Roman Empire" },
  { value: "BR_R", label: "British Raj" },
  { value: "RE_E", label: "Russian Empire" },
  { value: "KH_E", label: "Kingdom of Hawaii" },
  { value: "BW_A", label: "British West Africa" },
  { value: "FW_A", label: "French West Africa" },
  { value: "FI_I", label: "French Indochina" },
  { value: "DEI_E", label: "Dutch East Indies" },
  { value: "RH_E", label: "Rhodesia" },
  { value: "GEA_E", label: "German East Africa" },
  { value: "GSW_E", label: "German South West Africa" },
  { value: "IEA_E", label: "Italian East Africa" },
  { value: "SH_A", label: "Spanish Sahara" },
  { value: "PG_A", label: "Portuguese Guinea" },
  { value: "MN_E", label: "Manchukuo" },
  { value: "DAN_E", label: "Free City of Danzig" },
  { value: "DDR_E", label: "East Germany" },
  { value: "FRG_E", label: "West Germany" },
  { value: "SA_A", label: "Saarland" },
  { value: "FA_E", label: "French Algeria" },
  { value: "BSL_A", label: "Basutoland" },
  { value: "BCN_A", label: "Bechuanaland" },
  { value: "NY_A", label: "Nyasaland" },
  { value: "TA_A", label: "Tanganyika" },
  { value: "ZN_A", label: "Zanzibar" },
  { value: "HK_B", label: "British Hong Kong" },
  { value: "MC_P", label: "Portuguese Macau" },
  { value: "NH_E", label: "New Hebrides" },
  { value: "CI_I", label: "Caroline Islands" },
  { value: "NA_E", label: "Netherlands Antilles" },
  { value: "SS_A", label: "Straits Settlements" },
  { value: "TI_E", label: "Tibet" },
  { value: "KR_R", label: "Korea" },
  { value: "USSR_R", label: "Union of Soviet Socialist Republics" },

  { value: "SV_E", label: "South Vietnam" },
  { value: "NB_B", label: "North Borneo" },
  { value: "TR_E", label: "Transkei" },
  { value: "BF_E", label: "Biafra" },
  { value: "ET_E", label: "East Timor" },
  { value: "SU_E", label: "Soviet Union" },
  { value: "FM_E", label: "Federation of Malaya" },
  { value: "BP_E", label: "British Palestine" },
  { value: "CSA_E", label: "Confederate States of America" },
  { value: "GC_E", label: "Gran Colombia" },
  { value: "SH_R", label: "Saint Helena" },
  { value: "LM_M", label: "League of Nations Mandates" },
  { value: "TTPH_E", label: "Trust Territories of the Pacific Islands" },
  { value: "PBM_E", label: "Protectorate of Bohemia and Moravia" },
  { value: "MSL_E", label: "Mandate of Syria and Lebanon" },
  { value: "TX_E", label: "Republic of Texas" },
  { value: "KS_E", label: "Kingdom of Sarawak" },
  { value: "KR_E", label: "Kurdistan" },
  { value: "OF_E", label: "The Orange Free State" },
  { value: "ZR_R", label: "Republic of Zaire" },
];

const mergedCountries = [...countries, ...historicalCountries];

// Sort the merged array alphabetically by the `label` property
export const sortedCountries = mergedCountries.sort((a, b) =>
  a.label.localeCompare(b.label),
);

// src/components/SinglePost.tsx
import React, { useEffect, useState, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import Api from "../api/api";
import Header from "../common/Header";
import Footer from "../common/Footer";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { FaArrowLeft } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { allKeywords } from "../utils/constant";

interface BlogPost {
  _id: string;
  title: string;
  text: string;
  countries: string[];
  image?: string;
  createdAt: string;
  author?: {
    name: string;
    avatar?: string;
  };
}

const SinglePost: React.FC = () => {
  const { id: postId } = useParams<{ id: string }>();
  const history = useHistory();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const shareMenuRef = useRef<HTMLDivElement>(null);
  const [shareMenuOpen, setShareMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    if (postId) {
      fetchSinglePost(postId);
    }
  }, [postId]);

  const fetchSinglePost = async (id: string) => {
    try {
      const res = await Api.getBlogPost(id);
      setPost(res.data.data);
      setLoading(false);

      setLoading(false);
    } catch (err) {
      console.error("Error fetching the post:", err);
      setError("Failed to load the blog post.");
      setLoading(false);
    }
  };

  const handleShareClick = () => {
    setShareMenuOpen(!shareMenuOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      shareMenuRef.current &&
      !shareMenuRef.current.contains(event.target as Node)
    ) {
      setShareMenuOpen(false);
    }
  };

  useEffect(() => {
    if (shareMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [shareMenuOpen]);

  const getShareUrl = () => {
    return `${window.location.origin}/blog/${postId}`;
  };

  if (loading) {
    return (
      <>
        <Header />
        <main className="flex justify-center items-center h-screen">
          <p className="text-gray-500 text-xl">Loading...</p>
        </main>
        <Footer />
      </>
    );
  }

  if (!post) {
    return (
      <>
        <Header />
        <main className="flex justify-center items-center h-screen">
          <p className="text-red-500 text-xl">{error || "Post not found."}</p>
        </main>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{post.title} | Numisnest - Collectibles for sale</title>
        <meta
          name="description"
          content={stripHtmlTags(post.text).substring(0, 160)}
        />
        <meta
          name="keywords"
          content={allKeywords}
        />
        <meta
          property="og:locale:alternate"
          content="en_US"
        />
        {/* If you want to target Hebrew audience as well, you can add alternate locale */}
        <meta
          property="og:locale"
          content="he_IL"
        />
        {/* Add more meta tags as needed */}
      </Helmet>
      <Header />
      <main className="p-4 md:p-6 mx-auto max-w-4xl bg-white min-h-screen">
        <button
          onClick={() => history.push("/blog")}
          className="flex items-center text-gray-600 hover:text-gray-800 mb-4"
        >
          <FaArrowLeft className="mr-2" />
          Back to Posts
        </button>

        <article className="space-y-6">
          {/* Featured Image */}
          {post.image && (
            <img
              src={post.image}
              alt={post.title}
              className="w-full h-96 object-cover rounded-md shadow-lg"
              loading="lazy"
            />
          )}

          {/* Title */}
          <h1
            dir="auto"
            className="text-3xl md:text-4xl text-center font-bold text-gray-800"
          >
            {post.title}
          </h1>

          {/* Meta Information */}

          {/* Content */}
          <div
            dir="auto"
            className="prose prose-lg max-w-none"
            dangerouslySetInnerHTML={{ __html: post.text }}
          />

          {/* Tags or Categories */}
          {post.countries && post.countries.length > 0 && (
            <div className="flex flex-wrap items-center space-x-2">
              {post.countries.map(country => (
                <span
                  key={country}
                  className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                >
                  {country}
                </span>
              ))}
            </div>
          )}

          {/* 
           <div className="flex items-center space-x-4 text-gray-600 text-sm">
            {post.author?.avatar && (
              <img
                src={post.author.avatar}
                alt={post.author.name}
                className="w-10 h-10 rounded-full"
              />
            )}
            <div>
              <p className="font-medium">{post.author?.name || "Admin"}</p>
              <p>{new Date(post.createdAt).toLocaleDateString()}</p>
            </div>
          </div>
          <div className="relative inline-block">
            <button
              onClick={handleShareClick}
              className="flex items-center space-x-2 text-gray-600 hover:text-gray-800"
            >
              <span>Share</span>
            </button>

            {shareMenuOpen && (
              <div
                ref={shareMenuRef}
                className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg flex justify-around p-2 z-10"
              >
                <FacebookShareButton
                  url={getShareUrl()}
                  hashtag={post.title}
                >
                  <FacebookIcon
                    size={32}
                    round
                  />
                </FacebookShareButton>
                <TwitterShareButton
                  url={getShareUrl()}
                  title={post.title}
                >
                  <TwitterIcon
                    size={32}
                    round
                  />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={getShareUrl()}
                  title={post.title}
                >
                  <WhatsappIcon
                    size={32}
                    round
                  />
                </WhatsappShareButton>
              </div>
            )}
          </div>Share Buttons */}
        </article>
      </main>
      <Footer />
    </>
  );
};

// Helper function to strip HTML tags for meta description
const stripHtmlTags = (html: string) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};

export default SinglePost;

import React, { useEffect, useState } from "react";
import Api from "../../api/api"; // Assuming you have this to make API requests
import { useParams } from "react-router-dom"; // To get the userId from the route
import { FiArrowRight } from "react-icons/fi"; // Arrow icon for change direction
import Loader from "../../common/Loader"; // Loader component for loading state
import AdminHeader from "../../components/Admin/AdminHeader";
const fieldNameMap: { [key: string]: string } = {
  fname: "First Name",
  lname: "Last Name",
  email: "Email Address",
  phone: "Phone Number",
  country: "Country",
  about: "About",
  avatar: "Profile Picture",
  deliveryOption: "Delivery Option",
  lastChosenCurrency: "Preferred Currency",
  isVerified: "Email Verified",
  isVerifiedByAdmin: "Admin Verified",
  isDeactivated: "Account Status",
  description: "Description",
};
const AdminUserHistory: React.FC = () => {
  const { id } = useParams<any>(); // Assuming userId is part of the route
  const [user, setUser] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchHistory() {
      try {
        const res = await Api.getUser(id); // Assume you have this API method
        if (res.status === 200) {
          setUser(res.data.data);
        } else {
          setError("Failed to load history");
        }
      } catch (err) {
        setError("Error fetching user history");
      } finally {
        setLoading(false);
      }
    }
    fetchHistory();
  }, [id]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <>
      <AdminHeader />
      <div className="container mx-auto py-8">
        <h1 className="text-2xl font-bold text-center mb-6">
          {user?.fname} {} Profile Change History
        </h1>

        {user?.profileChanges?.length > 0 ? (
          <table className="min-w-full bg-white rounded-lg shadow-lg overflow-hidden">
            <thead>
              <tr className="bg-gray-200">
                <th className="text-left p-3 text-gray-700 font-semibold">
                  Changed
                </th>
                <th className="text-left p-3 text-gray-700 font-semibold">
                  Old Value
                </th>
                <th className="text-left p-3 text-gray-700 font-semibold"></th>{" "}
                {/* Empty for arrow */}
                <th className="text-left p-3 text-gray-700 font-semibold">
                  New Value
                </th>
                <th className="text-left p-3 text-gray-700 font-semibold">
                  Date Changed
                </th>
              </tr>
            </thead>
            <tbody>
              {user.profileChanges
                .sort(
                  (a, b) =>
                    new Date(b.changedAt).getTime() -
                    new Date(a.changedAt).getTime(),
                ) // Sort by date in descending order
                .map((change, index) => (
                  <tr
                    key={index}
                    className="border-b hover:bg-gray-100"
                  >
                    <td className="p-3 text-gray-700">
                      {fieldNameMap[change.fieldChanged] || change.fieldChanged}
                    </td>
                    <td className="p-3 text-gray-700">
                      {change.oldValue || "N/A"}
                    </td>
                    <td className="p-3 text-center">
                      <FiArrowRight className="text-green-500 w-6 h-6" />
                    </td>
                    <td className="p-3 text-gray-700">
                      {change.newValue || "N/A"}
                    </td>
                    <td className="p-3 text-gray-500">
                      {new Date(change.changedAt).toLocaleDateString("en-GB")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center text-gray-500">
            No changes found for this user.
          </p>
        )}
      </div>
    </>
  );
};

export default AdminUserHistory;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { IconType } from "react-icons";
import { FaArrowLeft, FaCoins, FaLocationDot } from "react-icons/fa6";
import ReactPaginate from "react-paginate";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Item from "../components/Items/Item";
import Filters from "../components/Items/Filters";
import { ISelectOption } from "../types/common";
import avatar from "../assets/avatar.jpg";
import { useAuth } from "../context/auth";
import Api from "../api/api";
import { IUser } from "../types/auth";
import { countries, europeanCountries } from "../utils/constant";
import { FaSpinner } from "react-icons/fa";
import { useCurrency } from "../context/currency";
import Loader from "../common/Loader";
import { isMobile } from "react-device-detect";
const UserItems = () => {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const hasSeller = q.get("seller");
  const searchQuery = q.get("q") || "";
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  const [urlParamsLoaded, setUrlParamsLoaded] = useState(false);
  const history = useHistory();
  const [userData, setUserData] = useState<any | undefined>();
  const [items, setItems] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [currencyChanged, setCurrencyChanged] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const { id: userId } = useParams<any>();
  const [debounceTimeout, setDebounceTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const { userCurrency, convert } = useCurrency();
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [showPage, setShowPage] = useState(false);
  const perPage = 72;

  const pageCount = Math.ceil(filteredItems.length / perPage);
  const currentPage = Math.floor(itemOffset / perPage);

  const [exchangeRates, setExchangeRates] = useState<any>({});
  const [selectedCategories, setSelectedCategories] = useState<
    ISelectOption[] | any
  >([]);
  const [selectedLocations, setSelectedLocations] = useState<
    ISelectOption[] | any
  >([]);
  const [selectedCountries, setSelectedCountries] = useState<
    ISelectOption[] | any
  >([]);
  const [locationOptions, setLocationOptions] = useState<
    ISelectOption[] | any[]
  >(["Everywhere"]);
  const [_location, setLocation] = useState("");
  const [currency, setCurrency] = useState("USD");
  const [search, setSearch] = useState("");
  const [years, setYears] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });
  const [prices, setPrices] = useState<{
    min: string | number;
    max: string | number;
  }>({ min: "", max: "" });
  const isSeller = useMemo(
    () => user?._id === hasSeller,
    [location.search, user],
  );
  const [restoreScrollPosition, setRestoreScrollPosition] = useState<
    number | null
  >(null);
  const encodeOptions = (options: ISelectOption[]) =>
    options.map(o => `${o.value}:${encodeURIComponent(o.label)}`).join(",");

  const decodeOptions = (optionString: string) =>
    optionString.split(",").map(pair => {
      const [value, label] = pair.split(":");
      return { value, label: decodeURIComponent(label) };
    });
  const handleItemClick = () => {
    const scrollPosition = window.scrollY;
    const currentPageLocal = Math.floor(itemOffset / perPage);
    sessionStorage.setItem("itemsScrollPosition", scrollPosition.toString());
    sessionStorage.setItem("itemsCurrentPage", currentPageLocal.toString());
  };
  const getUserCurrency = useCallback(async () => {
    if (user?.lastChosenCurrency) {
      setCurrency(user.lastChosenCurrency);
    } else {
      try {
        const response = await fetch("https://ipapi.co/json/");
        const locationData = await response.json();
        const countryCode = locationData.country_code;
        if (countryCode === "IL") {
          setCurrency("ILS");
        } else if (europeanCountries.includes(countryCode)) {
          setCurrency("EUR");
        } else {
          setCurrency("USD");
        }
      } catch (error) {
        setCurrency("USD");
      }
    }
  }, [user?.lastChosenCurrency]);
  useEffect(() => {
    getUserCurrency();
  }, [getUserCurrency]);
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * perPage) % filteredItems.length;
    setItemOffset(newOffset);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling
    });
  };
  const getUser = useCallback(async (id: string) => {
    try {
      const res = await Api.getUser(id);
      if (res.status === 200) {
        setUserData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
  const handleItemUpdate = async () => {
    await getUserItems();
  };
  const handleCurrencyChange = (newCurrency: string) => {
    setCurrency(newCurrency);
    setCurrencyChanged(true);
  };
  const populateLocationOptions = useCallback(() => {
    const uniqueCountries = Array.from(
      new Set(items.map(item => item.uid?.country as string)),
    ).filter(Boolean);
    const countryOptions: ISelectOption[] = countries
      .filter(country => uniqueCountries.includes(country.value))
      .map(country => ({
        value: country.value,
        label: country.label,
      }));
    countryOptions.unshift({ value: "", label: "Everywhere" });
    setLocationOptions(countryOptions);
  }, [items]);
  const handlePinToggle = async (itemId: string, isPinned: boolean) => {
    try {
      await Api.updateItem({ itemId: itemId, pinned: !isPinned });
      await getUserItems();
    } catch (error) {
      console.error("Failed to update pinned status", error);
    }
  };
  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 500);
    setDebounceTimeout(timeout);
    return () => clearTimeout(timeout);
  }, [
    searchQuery,
    items,
    selectedCategories,
    selectedLocations,
    currency,
    filteredItems,
  ]);
  useEffect(() => {
    if (hasSeller) {
      getUser(hasSeller);
    }
  }, [location.search]);
  useEffect(() => {
    const fetchUserAndItems = async () => {
      await getUser(userId);
      await getUserItems();
    };
    fetchUserAndItems();
  }, [userId]);

  const getExchangeRates = async () => {
    try {
      const response = await fetch(
        "https://api.exchangerate-api.com/v4/latest/USD",
      );
      const data = await response.json();
      setExchangeRates(data.rates);
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
    }
  };
  const handleItemDelete = async (itemId: string) => {
    setItems(prevItems => prevItems.filter(item => item._id !== itemId));
  };
  async function getUserItems() {
    const res: any = await Api.getUserItem({ id: userId });
    if (res.status == 200) {
      setItems(res?.data?.data);
    }
  }

  useEffect(() => {
    getExchangeRates();
  }, []);

  useEffect(() => {
    populateLocationOptions();
  }, [items, populateLocationOptions]);

  // NEW: Read filters from URL parameters on component mount
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Read categories from URL
    const categoriesParam = queryParams.get("categories");
    if (categoriesParam) {
      const selectedCategoriesFromURL = decodeOptions(categoriesParam);
      setSelectedCategories(selectedCategoriesFromURL);
    }
    // Read countries from URL
    const countriesParam = queryParams.get("countries");
    if (countriesParam) {
      const selectedCountriesFromURL = decodeOptions(countriesParam);
      setSelectedCountries(selectedCountriesFromURL);
    }

    // Read years from URL
    const yearMinParam = queryParams.get("yearMin");
    const yearMaxParam = queryParams.get("yearMax");
    if (yearMinParam || yearMaxParam) {
      setYears({
        min: yearMinParam || "",
        max: yearMaxParam || "",
      });
    }

    // Read prices from URL
    const priceMinParam = queryParams.get("priceMin");
    const priceMaxParam = queryParams.get("priceMax");
    if (priceMinParam || priceMaxParam) {
      setPrices({
        min: priceMinParam || "",
        max: priceMaxParam || "",
      });
    }
    setUrlParamsLoaded(true);
  }, []);

  // NEW: Update URL parameters when filters change
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // Remove existing filter parameters

    queryParams.delete("yearMin");
    queryParams.delete("yearMax");
    queryParams.delete("priceMin");
    queryParams.delete("priceMax");

    // Remove existing filter parameters
    queryParams.delete("categories");
    // Remove existing countries parameter
    queryParams.delete("countries");

    // Add countries as value-label pairs
    if (selectedCountries.length > 0) {
      queryParams.set("countries", encodeOptions(selectedCountries));
    }
    // Add categories as label-value pairs
    if (selectedCategories.length > 0) {
      queryParams.set("categories", encodeOptions(selectedCategories));
    }

    if (years.min) {
      queryParams.set("yearMin", years.min.toString());
    }
    if (years.max) {
      queryParams.set("yearMax", years.max.toString());
    }

    if (prices.min) {
      queryParams.set("priceMin", prices.min.toString());
    }
    if (prices.max) {
      queryParams.set("priceMax", prices.max.toString());
    }

    // Preserve other query parameters like 'q' and 'seller'
    const qParam = q.get("q");
    if (qParam) {
      queryParams.set("q", qParam);
    }
    const sellerParam = q.get("seller");
    if (sellerParam) {
      queryParams.set("seller", sellerParam);
    }

    // Update the URL without reloading the page
    history.replace({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  }, [selectedCategories, selectedCountries, years, prices]);
  useEffect(() => {
    const storedScrollPosition = sessionStorage.getItem("itemsScrollPosition");
    const storedCurrentPage = sessionStorage.getItem("itemsCurrentPage");

    if (storedScrollPosition !== null && storedCurrentPage !== null) {
      const scrollPosition = parseInt(storedScrollPosition, 10);
      const currentPageLocal = parseInt(storedCurrentPage, 10);
      setItemOffset(currentPageLocal * perPage);
      setRestoreScrollPosition(scrollPosition);

      // Clear session storage to avoid repeated restoration
      sessionStorage.removeItem("itemsScrollPosition");
      sessionStorage.removeItem("itemsCurrentPage");
    }
  }, []);
  useEffect(() => {
    if (restoreScrollPosition !== null && filteredItems.length > 0) {
      window.scrollTo(0, restoreScrollPosition);
      setRestoreScrollPosition(null);
    }
  }, [restoreScrollPosition, filteredItems]);
  if (
    false &&
    userData?.visibilitySettings?.itemsVisible !== true &&
    user?._id !== userId
  ) {
    return (
      <>
        <Header />
        <div className="flex justify-center items-center h-screen">
          <h1 className="text-3xl">This user has disabled their items</h1>
        </div>
        <Footer />
      </>
    );
  }
  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-1 w-full">
            {userData && !isMobile && (
              <div className="mx-auto max-w-[1560px] mb-[-2.5rem] ml-[1.3rem] px-1 w-full flex items-center justify-start">
                <button
                  onClick={() => history.push(`/seller/${userId}`)}
                  className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-md shadow hover:bg-blue-700 transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                  aria-label="Back to Seller Profile"
                >
                  <FaArrowLeft
                    fill="#fff"
                    size={20}
                  />
                  <span className="font-medium text-white text-sm">
                    {`${userData?.fname}'s profile`}
                  </span>
                </button>
              </div>
            )}
            <div className="px-6">
              <p className="text-xl md:text-2xl font-bold mb-3 text-center">
                {userData && !hasSeller ? `${userData?.fname}'s items` : ``}
              </p>
              <Filters
                selectedCurrency={currency}
                setFilteredItems={setFilteredItems}
                items={items}
                setLoading={setLoading}
                isLoading={loading}
                searchInProgress={searchInProgress}
                setSearchInProgress={setSearchInProgress}
                filteredItems={filteredItems}
                search={search}
                isSellerItems={true}
                setSearch={setSearch}
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                selectedCountries={selectedCountries}
                setSelectedCountries={setSelectedCountries}
                years={years}
                setYears={setYears}
                prices={prices}
                setPrices={setPrices}
                setItemsOffset={setItemOffset}
                urlParamsLoaded={urlParamsLoaded}
                exchangeRates={exchangeRates}
                convertedPrices={items.map(i => ({
                  id: i._id,
                  convertedPrice: convert(i.price, i.currency, currency),
                  currency: i.selectedCurrency || currency,
                }))}
                setCurrencyChanged={setCurrencyChanged}
                currencyChanged={currencyChanged}
              />
            </div>
            {loading || !showPage ? (
              <div className="flex justify-center items-center py-10">
                <FaSpinner className="animate-spin text-3xl text-primary" />
              </div>
            ) : (
              <div className="grid gap-2 md:gap-4 grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                {filteredItems
                  .slice(itemOffset, itemOffset + perPage)
                  .map((item, idx) => (
                    <Item
                      key={item._id || idx}
                      isPinned={item.pinned}
                      isFeatured={item.pinned}
                      isCountry={item.isCountry}
                      isSeller={user?._id === item.uid?._id}
                      name={item.name}
                      uid={item.uid._id}
                      user_data={item.uid}
                      description={item.description}
                      country={item.countries}
                      photos={item.photos}
                      category={item.categories}
                      currency={userCurrency}
                      price={convert(item.price, item.currency, userCurrency)}
                      year={item?.year}
                      hidden={item?.hidden}
                      id={item?._id}
                      createdAt={item?.createdAt}
                      updatedAt={item?.updatedAt}
                      onPinToggle={handlePinToggle}
                      setCurrencyChanged={setCurrencyChanged}
                      currencyChanged={currencyChanged}
                      onDelete={handleItemDelete}
                      onUpdate={handleItemUpdate}
                      onItemClick={handleItemClick}
                    />
                  ))}
              </div>
            )}
          </div>
          <br />
          <div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              forcePage={currentPage}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="flex flex-wrap justify-center mt-4"
              pageClassName="mx-2"
              pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              previousClassName="mx-2"
              previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              nextClassName="mx-2"
              nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              breakClassName="mx-2"
              breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              activeClassName="bg-slate-200 rounded-full "
              activeLinkClassName="font-semibold bg-slate-200"
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
const Select = ({
  Icon,
  title,
  options,
  value,
  setValue,
}: {
  Icon: IconType;
  title: string;
  options: ISelectOption[];
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  return (
    <>
      <Icon
        size={22}
        fill="var(--primary)"
      />
      <span className="font-medium text-sm text-primary">{title}</span>
      <select
        value={value}
        onChange={e => setValue(e.target.value)}
        className="rounded-md outline-none border border-primary px-3 py-2 text-sm"
      >
        {options.map((itm, idx) => (
          <option
            key={idx}
            value={itm.value}
          >
            {itm.label}
          </option>
        ))}
      </select>
    </>
  );
};
export default UserItems;

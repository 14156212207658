// src/components/MetaTags.tsx
import React from "react";
import { Helmet } from "react-helmet";

interface MetaTagsProps {
  meta: {
    title: string;
    description: string;
    image?: string;
    url?: string;
    type?: string;
    locale?: string;
    alternateLocale?: string;
    [key: string]: any;
  };
}

const MetaTags: React.FC<MetaTagsProps> = ({ meta }) => {
  const {
    title,
    description,
    image,
    url,
    type,
    locale,
    alternateLocale,
    ...otherMeta
  } = meta;

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta
        name="description"
        content={description}
      />

      {/* Open Graph / Facebook */}
      <meta
        property="og:title"
        content={title}
      />
      <meta
        property="og:description"
        content={description}
      />

      {url && (
        <meta
          property="og:url"
          content={url}
        />
      )}
      <meta
        property="og:type"
        content={type}
      />
      <meta
        property="og:locale"
        content={locale}
      />
      {alternateLocale && (
        <meta
          property="og:locale:alternate"
          content={alternateLocale}
        />
      )}
      {image && (
        <meta
          property="og:image"
          content={image}
        />
      )}
      {/* Twitter */}
      <meta
        name="twitter:card"
        content="summary_large_image"
      />
      <meta
        name="twitter:title"
        content={title}
      />
      {description && (
        <meta
          name="twitter:description"
          content={description}
        />
      )}
      <meta
        property="og:image:width"
        content="1200"
      />
      <meta
        property="og:image:height"
        content="630"
      />
      {image && (
        <meta
          name="twitter:image"
          content={image}
        />
      )}

      {/* Additional Meta Tags */}
      {Object.entries(otherMeta).map(([key, value]) => (
        <meta
          key={key}
          name={key}
          content={value}
        />
      ))}
    </Helmet>
  );
};

export default MetaTags;

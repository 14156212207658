import React, { useEffect, useState } from "react";
import { useCurrency } from "../../context/currency";
import { isMobile } from "react-device-detect";

interface AuctionPriceProps {
  bidStatus: string;
  currentBid: number;
  remainingTime: number; // Remaining time in milliseconds
  currency: string;
  isSold: boolean;
  hasBid?: boolean;
}

const AuctionPrice: React.FC<AuctionPriceProps> = ({
  bidStatus,
  currentBid,
  remainingTime,
  hasBid = true,
  isSold,
  currency,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(remainingTime);
  const [isHovered, setIsHovered] = useState(false);
  const { userCurrency, convert } = useCurrency();

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => (prevTime > 1000 ? prevTime - 1000 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getBidClass = () => {
    if (isSold) {
      return "bg-blue-500 text-white";
    }
    if (!hasBid) {
      return "bg-gray-500 text-white";
    }
    if (timeLeft <= 0) {
      return "bg-gray-500 text-white";
    }
    switch (bidStatus) {
      case "":
        return "bg-blue-500 text-white";

      case "userLeading":
        return "bg-green-500 text-white";
      case "outbid":
        return "bg-red-500 !text-white";
      default:
        return "bg-gray-300 text-white";
    }
  };

  const getBidText = () => {
    if (isSold) {
      return "Sold for:";
    }
    if (timeLeft <= 0) {
      return "Ended:";
    }
    if (!hasBid) {
      return "Start Price:";
    }
    switch (bidStatus) {
      case "":
        return "Leading Bid:";
      case "userLeading":
        const bidText = timeLeft > 0 ? "You Leading Bid:" : "Sold to you for:";
        return bidText;
      case "outbid":
        return "Outbid:";
      default:
        return "Ended:";
    }
  };

  const formatTimeLeft = (ms: number, hoverFormat = false) => {
    if (ms <= 0) return isMobile ? "Auction Ended" : "Auction Ended";

    const totalSeconds = Math.floor(ms / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    if (ms > 24 * 3600 * 1000) {
      const date = new Date(Date.now() + ms).toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
      });
      const time = new Date(Date.now() + ms).toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      if (hoverFormat) {
        return `${days} day${days > 1 ? "s" : ""} and ${hours} hour${hours > 1 ? "s" : ""} left.`;
      }
      if (timeLeft <= 0) {
        return `${date}, ${time}`;
      }

      return isMobile
        ? `${days}d ${hours}h`
        : `${days}d ${hours}h (${date}, ${time})`;
    } else if (ms > 3600 * 1000) {
      if (hoverFormat) {
        return `${hours} hour${hours > 1 ? "s" : ""} and ${minutes} minute${minutes > 1 ? "s" : ""} left.`;
      }
      return `${hours}h ${minutes}m`;
    } else if (ms > 5 * 60 * 1000) {
      if (hoverFormat) {
        return `${minutes} minute${minutes > 1 ? "s" : ""} and ${seconds} second${seconds > 1 ? "s" : ""} left.`;
      }
      return `${minutes}m ${seconds}s`;
    } else {
      if (hoverFormat) {
        return `${totalSeconds} second${totalSeconds > 1 ? "s" : ""} left.`;
      }
      if (bidStatus === "outbid") {
        return (
          <span className="text-white font-bold">{`${minutes}m ${seconds}s`}</span>
        );
      }
      return (
        <span className="text-red-500 font-bold">{`${minutes}m ${seconds}s`}</span>
      );
    }
  };

  const formattedTimeLeft = formatTimeLeft(timeLeft);
  const hoverTimeLeft = formatTimeLeft(timeLeft, true);
  const hoverMessage =
    timeLeft > 0 ? "Bidding will finish on" : "Bidding finished on";

  return (
    <div
      className={`relative ml-[-0.7rem] mb-[-0.7rem] mr-[-0.7rem] mt-[1rem] flex flex-col items-start justify-between ${getBidClass()} shadow-md rounded-b-xl`}
    >
      <p className="ml-0.5 text-sm text-white font-semibold">{getBidText()}</p>
      <div className="flex justify-between w-full">
        <p className="ml-0.5 text-lg text-white font-bold whitespace-nowrap">{`${userCurrency} ${convert(currentBid, currency, userCurrency)}`}</p>
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <p className="text-sm text-white mt-2 mr-0.5 max-md:text-xs line-clamp-1">
            {formattedTimeLeft}
          </p>
        </div>
      </div>

      {isHovered && (
        <div
          style={{ zIndex: 1000 }}
          className="absolute lg:min-w-[250px] bottom-full left-1/2 mt-2 transform -translate-x-1/2 bg-orange-200 border border-orange-400 text-xs rounded-md px-3 py-2 shadow-lg"
        >
          <p>
            <span className="font-semibold">{hoverTimeLeft}</span>
          </p>
          <p>
            {hoverMessage}{" "}
            <span className="font-bold">
              {new Date(Date.now() + remainingTime).toLocaleDateString("en-GB")}
              {" at "}
              {new Date(Date.now() + remainingTime).toLocaleTimeString(
                "en-GB",
                {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                },
              )}
            </span>
          </p>
        </div>
      )}
    </div>
  );
};

export default AuctionPrice;

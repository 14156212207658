import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Api from "../../api/api";
import { useAuth } from "../../context/auth";
import { CiSettings } from "react-icons/ci";
import { links } from "../seller";
import avatar from "../../assets/avatar.jpg";
import { IUser } from "../../types/auth";
import { FaCamera } from "react-icons/fa";
import { countries } from "../../utils/constant";
import PhoneInput from "react-phone-input-2";
import Button from "../../common/Button";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import { REACT_APP_KEY_NAME } from "../../utils/constant";
import Modal from "react-modal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
Modal.setAppElement("#root");

const EditProfile: React.FC = () => {
  const [user, setUser] = useState<IUser>({
    fname: "",
    lname: "",
    country: "",
    phone: "",
    email: "",
    about: "",
    description: "",
    deliveryOption: "",
    avatar: "",
    _id: "",
    isDeactivated: false,
    isVerified: false,
    contact: "",
  });

  const imgRef = useRef<HTMLInputElement>(null);
  const [photo, setPhoto] = useState<string>(user?.avatar || avatar);
  const [tempAvatar, setTempAvatar] = useState<string>("");
  const { user: isUser } = useAuth();
  const [isShow, setIsShow] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (isUser?._id) {
      getUser(isUser?._id);
    }
  }, [isUser?._id]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    setPhoto(user?.avatar || avatar);
  }, [user]);
  const handleTextAreaChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    linesAllowed: number,
  ) => {
    const { value, name } = e.target;

    const lineCount = value.split("\n").length;

    if (lineCount > linesAllowed) {
      toast.error(`You can only add up to ${linesAllowed} lines.`);
      return;
    }

    setUser(prev => ({ ...prev, [name]: value }));
  };
  const handleDeleteAccount = async () => {
    try {
      const res = await Api.deleteUser(user?._id);
      if (res.status === 200) {
        toast.success("Account deleted successfully");
        Cookies.remove(REACT_APP_KEY_NAME);
        localStorage.removeItem(REACT_APP_KEY_NAME);
        history.push("/");
        window.location.reload();
        history.push("/");
      }
    } catch (error) {
      console.error("Error deleting account", error);
      alert("Failed to delete the account");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setIsDisable(true);
    try {
      // Handle Avatar Upload
      if (tempAvatar) {
        const avatarUploadResponse = await Api.updateAvatar(tempAvatar);
        if (avatarUploadResponse) {
          setUser(prev => ({ ...prev, avatar: avatarUploadResponse.url }));
        } else {
          throw new Error("Failed to upload avatar.");
        }
      }

      // Handle Profile Data Update
      const res = await Api.updateMyProfile(user);
      if (res.status === 200) {
        toast.success(res.message || "Profile updated successfully");
        //if email changed log out and redirect to login page
        if (user.email !== isUser?.email) {
          Cookies.remove(REACT_APP_KEY_NAME);
          localStorage.removeItem(REACT_APP_KEY_NAME);
          window.location.href = "/auth/login";
        }
        history.push(`/seller/${isUser?._id}`);
        toast.success("Email updated successfully. Please login again.");
        toast.success(res.message || "Profile updated successfully");
        await getUser(isUser?._id || "");
      } else {
      }
    } catch (error: any) {
      toast.error(error.message || "Error updating profile");
    } finally {
      setIsDisable(false);
    }
  };

  async function getUser(id: string) {
    const res: any = await Api.getUser(id);
    if (res.status === 200) {
      setUser(res?.data?.data);
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { value, name } = e.target;
    setUser(prev => ({ ...prev, [name]: value }));
  };

  const handleAvatarSubmit = async () => {
    if (photo === user.avatar) {
      return;
    }
    setIsDisable(true);
    try {
      const res = await Api.updateAvatar(photo);
      if (res) {
        setUser(prev => ({ ...prev, avatar: res.url }));
        setPhoto(res.url);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDisable(false);
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTempAvatar(reader.result as string);
        setPhoto(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handlers for rich text editors with character limits
  const handleDeliveryOptionChange = (value: string) => {
    const text = value.replace(/<[^>]+>/g, "");
    if (text.length <= 250) {
      setUser(prev => ({ ...prev, deliveryOption: value }));
    }
  };

  const handleContactChange = (value: string) => {
    const text = value.replace(/<[^>]+>/g, "");
    if (text.length <= 250) {
      setUser(prev => ({ ...prev, contact: value }));
    }
  };

  const handleDescriptionChange = (value: string) => {
    const text = value.replace(/<[^>]+>/g, "");
    if (text.length <= 500) {
      setUser(prev => ({ ...prev, description: value }));
    }
  };

  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[900px] px-6 w-full">
            {/* Account Settings Button */}
            <div className="w-[192px] relative mb-6">
              <button
                onClick={() => setIsShow(prev => !prev)}
                className="text-sm w-full rounded-lg justify-center bg-primary text-white flex items-center gap-2 py-3 px-5"
              >
                <CiSettings
                  size={20}
                  fill="#fff"
                />
                Account
              </button>
              {isShow && (
                <div className="absolute z-40 left-0 right-0 top-11 rounded-xl bg-white mt-2 py-2">
                  {links.map((itm, idx) => (
                    <div
                      key={idx}
                      className="flex items-center gap-2 py-3 px-5 hover:bg-gray-100"
                    >
                      <itm.icon
                        size={16}
                        fill="#4979d1"
                      />
                      {/* For Delete Account, open the modal */}
                      {itm.title === "Delete account" ? (
                        <button
                          className="text-sm text-primary hover:underline"
                          onClick={() => {
                            setIsDeleteModalOpen(true);
                            setIsShow(false);
                          }}
                        >
                          {itm.title}
                        </button>
                      ) : (
                        <Link
                          to={itm.link}
                          className="text-sm text-primary hover:underline"
                        >
                          {itm.title}
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Edit Profile Form */}
            <form
              onSubmit={handleSubmit}
              className="bg-white rounded-2xl p-4 md:p-6"
            >
              <input
                id="avatar"
                type="file"
                accept="image/png, image/jpeg, image/webp"
                className="hidden"
                ref={imgRef}
                onChange={handleImageChange}
                disabled={isDisable}
              />

              {/* Avatar */}
              <label
                htmlFor="avatar"
                className="w-fit cursor-pointer block relative mb-6"
              >
                <FaCamera
                  fill="var(--primary)"
                  className="absolute right-1 bottom-1"
                />
                <img
                  src={photo ?? avatar}
                  alt="avatar"
                  className="w-40 h-40 object-cover rounded-xl"
                />
              </label>

              {/* Personal Details */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <p className="font-semibold">First Name</p>
                  <input
                    name="fname"
                    value={user?.fname}
                    onChange={handleInputChange}
                    className="w-full border-b border-[#000] py-2 text-[15px]"
                    required
                    disabled={isDisable}
                  />
                </div>
                <div>
                  <p className="font-semibold">Last Name</p>
                  <input
                    name="lname"
                    value={user?.lname}
                    onChange={handleInputChange}
                    className="w-full border-b border-[#000] py-2 text-[15px]"
                    required
                    disabled={isDisable}
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <p className="font-semibold">Country</p>
                  <select
                    name="country"
                    value={user?.country}
                    onChange={handleInputChange}
                    className="w-full border-b border-[#000] py-2 text-[15px]"
                    required
                    disabled={isDisable}
                  >
                    {countries?.map((itm, idx) => (
                      <option
                        key={idx}
                        value={itm.value}
                      >
                        {itm.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <p className="font-semibold">Phone Number</p>
                  <PhoneInput
                    country={undefined}
                    value={user?.phone || ""}
                    containerClass="bg-[#fff] border-b border-[#000] rounded-none text-sm w-full"
                    inputClass="!h-[40px] !w-full !bg-[#fff] !border-none !rounded-none !pl-10"
                    buttonClass="!bg-[#fff] !border-none !rounded-none"
                    onChange={(value: string) =>
                      setUser(prev => ({ ...prev, phone: value }))
                    }
                    disabled={isDisable}
                  />
                </div>
              </div>

              {/* Email */}
              <div className="mb-4 w-full">
                <p className="font-semibold">Email</p>
                <input
                  type="email"
                  name="email"
                  value={user?.email || ""}
                  onChange={handleInputChange}
                  className="w-full border-b border-[#000] py-2 text-[15px]"
                  required
                  disabled={isDisable}
                />
              </div>

              {/* About */}
              <div className="mb-4 w-full">
                <p className="font-semibold">About</p>
                <input
                  name="about"
                  dir="auto"
                  value={user?.about || ""}
                  onChange={handleInputChange}
                  maxLength={70}
                  className="w-full border-b border-[#000] py-2 text-[15px]"
                  disabled={isDisable}
                />
                <p className="text-end text-xs mt-1">
                  {user?.about?.length || 0}/70 characters
                </p>
              </div>

              {/* Profile Description */}
              <div className="mb-4">
                <p className="font-semibold mb-1">Profile Description</p>
                <textarea
                  name="description"
                  dir="auto"
                  value={user.description}
                  onChange={e => handleTextAreaChange(e, 5)}
                  maxLength={500}
                  style={{ whiteSpace: "pre-line" }}
                  className="w-full border border-[#000] min-w-full max-w-full min-h-[150px] rounded-xl p-2 text-[15px]"
                ></textarea>

                <p className="text-end text-xs mt-1">
                  {user?.description?.replace(/<[^>]+>/g, "").length}/500
                  characters
                </p>
              </div>

              {/* Delivery Option */}
              <div className="mb-4">
                <p className="font-semibold mb-1">Delivery Option</p>
                <textarea
                  name="deliveryOption"
                  dir="auto"
                  value={user.deliveryOption}
                  onChange={e => handleTextAreaChange(e, 3)}
                  maxLength={250}
                  style={{ whiteSpace: "pre-line" }}
                  className="w-full border border-[#000] min-w-full max-w-full min-h-[80px] rounded-xl p-2 text-[15px]"
                ></textarea>
                <p className="text-end text-xs mt-1">
                  {user?.deliveryOption?.replace(/<[^>]+>/g, "").length}/250
                  characters
                </p>
              </div>

              {/* Contact */}
              <div className="mb-4">
                <p className="font-semibold mb-1">Contact</p>
                <textarea
                  name="contact"
                  dir="auto"
                  value={user.contact}
                  onChange={e => handleTextAreaChange(e, 3)}
                  maxLength={250}
                  style={{ whiteSpace: "pre-line" }}
                  className="w-full border border-[#000] min-w-full max-w-full min-h-[80px] rounded-xl p-2 text-[15px]"
                ></textarea>
                <p className="text-end text-xs mt-1">
                  {user?.contact?.replace(/<[^>]+>/g, "").length}/250 characters
                </p>
              </div>

              {/* Submit Button */}
              <div className="flex justify-end mt-8">
                <Button
                  type="submit"
                  className="!w-fit !px-6"
                  disabled={isDisable}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </main>
      <Footer />

      {/* Delete Account Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={() => setIsDeleteModalOpen(false)}
        contentLabel="Confirm Delete Account"
        className="bg-white rounded-lg p-6 max-w-lg mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      >
        <h2 className="text-xl font-semibold mb-4">Confirm Delete Account</h2>
        <p>
          Are you sure you want to delete your account? This action cannot be
          undone.
        </p>
        <div className="flex justify-end gap-4 mt-6">
          <button
            onClick={() => setIsDeleteModalOpen(false)}
            className="px-4 py-2 bg-gray-200"
          >
            Cancel
          </button>
          <button
            onClick={handleDeleteAccount}
            className="px-4 py-2 bg-red-500 text-white"
          >
            Yes, Delete
          </button>
        </div>
      </Modal>
    </>
  );
};

export default EditProfile;

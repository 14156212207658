import React, { useState, useEffect, ReactNode, useRef } from "react";
import ReactDOM from "react-dom";
import {
  AiOutlineFullscreen,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { FaTimes } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import { useSwipeable } from "react-swipeable";
import { isVideo } from "../utils/helpers";
import Item from "./Items/Item";
import { allKeywords } from "../utils/constant";

interface ImageHandlerProps {
  photos: string[];
  selectedPhoto: string;
  setSelectedPhoto: (photo: string) => void;
  children?: ReactNode;
  className?: string;
  onEnlargedChange?: (isEnlarged: boolean) => void;
  imageSize?: any;
  isSold?: boolean;
}

const ImageHandler: React.FC<ImageHandlerProps> = ({
  photos,
  selectedPhoto,
  onEnlargedChange,
  setSelectedPhoto,
  children,
  className,
  isSold = false,
  imageSize,
}) => {
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [currentIndexLocal, setCurrentIndexLocal] = useState(0);
  const [zoomOrigin, setZoomOrigin] = useState({ xPercent: 50, yPercent: 50 });
  const imgRef = useRef<HTMLImageElement>(null);
  const [swipePosition, setSwipePosition] = useState(0);
  const [finalSwipePosition, setFinalSwipePosition] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [transitionDirection, setTransitionDirection] = useState<
    null | "next" | "prev"
  >(null);
  const [hoveredPhoto, setHoveredPhoto] = useState<string | null>(null);
  const zoomLevel = 3; // Adjust this value as needed
  const [panPosition, setPanPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const newIndex = photos.indexOf(selectedPhoto);
    if (newIndex !== -1) {
      setCurrentIndexLocal(newIndex);
    }
  }, [selectedPhoto, photos]);

  const handleImageClick = () => {
    if (isMobile) {
      setIsEnlarged(true);
    } else {
      setIsZoomed(prev => !prev);
    }
  };

  const handleFullScreenToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsEnlarged(true);
  };

  const handleClose = () => {
    setIsEnlarged(false);
    setIsZoomed(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!imgRef.current || !isZoomed) return;

    const containerRect = e.currentTarget.getBoundingClientRect();
    const cursorX = e.clientX - containerRect.left;
    const cursorY = e.clientY - containerRect.top;

    const containerWidth = containerRect.width;
    const containerHeight = containerRect.height;

    const xPercent = (cursorX / containerWidth) * 100;
    const yPercent = (cursorY / containerHeight) * 100;

    const constrainedXPercent = Math.max(0, Math.min(xPercent, 100));
    const constrainedYPercent = Math.max(0, Math.min(yPercent, 100));

    imgRef.current.style.transformOrigin = `${constrainedXPercent}% ${constrainedYPercent}%`;
    setZoomOrigin({
      xPercent: constrainedXPercent,
      yPercent: constrainedYPercent,
    });
  };

  const maxTransform = window.innerWidth * 0.7; // Same as maxSwipe
  const clampedTransformX = Math.max(
    -window.innerWidth + finalSwipePosition - maxTransform,
    Math.min(
      -window.innerWidth + swipePosition + finalSwipePosition,
      -window.innerWidth + finalSwipePosition + maxTransform,
    ),
  );

  const handleZoomClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isZoomed) {
      imgRef.current!.style.transformOrigin = "center center";
      setIsZoomed(false);
    } else {
      const containerRect = e.currentTarget.getBoundingClientRect();
      const offsetX = e.clientX - containerRect.left;
      const offsetY = e.clientY - containerRect.top;

      const width = containerRect.width;
      const height = containerRect.height;

      const xPercent = (offsetX / width) * 100;
      const yPercent = (offsetY / height) * 100;

      const constrainedXPercent = Math.max(0, Math.min(xPercent, 100));
      const constrainedYPercent = Math.max(0, Math.min(yPercent, 100));

      imgRef.current!.style.transformOrigin = `${constrainedXPercent}% ${constrainedYPercent}%`;
      setZoomOrigin({
        xPercent: constrainedXPercent,
        yPercent: constrainedYPercent,
      });
      setIsZoomed(true);
    }
  };

  const handleSwipeEnd = (deltaX: number) => {
    const threshold = 50; // Adjust as needed

    if (deltaX < -threshold) {
      // Swiped left, show next image
      const distanceToMove = -window.innerWidth - swipePosition;
      setFinalSwipePosition(distanceToMove);
      setTransitionDirection("next");
    } else if (deltaX > threshold) {
      // Swiped right, show previous image
      const distanceToMove = window.innerWidth - swipePosition;
      setFinalSwipePosition(distanceToMove);
      setTransitionDirection("prev");
    } else {
      // Not enough swipe, reset position
      setFinalSwipePosition(-swipePosition);
      setTransitionDirection(null);
    }

    setIsTransitioning(true);
  };

  const swipeHandlers = useSwipeable({
    onSwiping: eventData => {
      if (isMobile) {
        if (isZoomed) {
          setIsZoomed(false);
        } else {
          const maxSwipe = window.innerWidth * 0.7;
          const deltaX = eventData.deltaX;
          const clampedDeltaX = Math.max(-maxSwipe, Math.min(deltaX, maxSwipe));
          setSwipePosition(clampedDeltaX);
        }
      }
    },
    onSwiped: eventData => {
      if (isMobile) {
        handleSwipeEnd(eventData.deltaX);
      }
    },
    preventScrollOnSwipe: false,
    trackTouch: true,
  });
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (isEnlarged) {
        if (e.key === "Escape") {
          // Close the enlarged view on "Esc"
          handleClose();
        } else if (e.key === "ArrowRight") {
          // Move to the next image on "ArrowRight"
          nextImage();
        } else if (e.key === "ArrowLeft") {
          // Move to the previous image on "ArrowLeft"
          prevImage();
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isEnlarged, currentIndexLocal, photos]);
  useEffect(() => {
    if (onEnlargedChange) {
      onEnlargedChange(isEnlarged);
    }
  }, [isEnlarged, onEnlargedChange]);

  const getImagesForLooping = () => {
    const currentPhoto = hoveredPhoto || selectedPhoto;
    const currentIndex = photos.indexOf(currentPhoto);
    const prevIndex = (currentIndex - 1 + photos.length) % photos.length;
    const nextIndex = (currentIndex + 1) % photos.length;

    return [
      photos[prevIndex], // Previous Image
      currentPhoto, // Current Image
      photos[nextIndex], // Next Image
    ];
  };

  const imagesForLooping = getImagesForLooping();
  const transformX = -window.innerWidth + swipePosition + finalSwipePosition;

  const handleTransitionEnd = () => {
    setIsTransitioning(false);
    setSwipePosition(0);
    setFinalSwipePosition(0);

    if (transitionDirection === "next") {
      setCurrentIndexLocal((currentIndexLocal + 1) % photos.length);
      setSelectedPhoto(photos[(currentIndexLocal + 1) % photos.length]);
    } else if (transitionDirection === "prev") {
      setCurrentIndexLocal(
        (currentIndexLocal - 1 + photos.length) % photos.length,
      );
      setSelectedPhoto(
        photos[(currentIndexLocal - 1 + photos.length) % photos.length],
      );
    }

    setTransitionDirection(null);
  };

  const nextImage = () => {
    const nextIndex = (currentIndexLocal + 1) % photos.length;
    setCurrentIndexLocal(nextIndex);
    setSelectedPhoto(photos[nextIndex]);
    setSwipePosition(0);
  };

  const prevImage = () => {
    const prevIndex = (currentIndexLocal - 1 + photos.length) % photos.length;
    setCurrentIndexLocal(prevIndex);
    setSelectedPhoto(photos[prevIndex]);
    setSwipePosition(0);
  };

  return (
    <>
      {/* Main Image Container */}
      <div
        className={`relative overflow-hidden ${className}`}
        onClick={isMobile ? handleImageClick : undefined}
        {...(isMobile ? swipeHandlers : {})}
      >
        <div
          className="relative inline-block"
          style={{ width: "fit-content" }}
        >
          {isSold && (
            <div
              className="sold-ribbon-overlay absolute top-0 left-[0] sm:left-[-0] w w-1/2 h-1/2 pointer-events-none"
              style={{ zIndex: 10 }}
            >
              <svg
                viewBox="0 0 200 200"
                preserveAspectRatio="xMinYMin meet"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full h-full"
              >
                <polygon
                  points="0,0 200,0 200,0 0,200 0,200"
                  fill="#1a6bee"
                />
                <text
                  x={isMobile ? "110" : "90"}
                  y="70"
                  fontSize="40"
                  textAnchor="middle"
                  fill="white"
                  fontWeight="bold"
                  transform="rotate(-45 100 100)"
                >
                  SOLD
                </text>
              </svg>
            </div>
          )}
          {/* Render the children or main image */}
          {children}
          {/* Fullscreen Icon */}
          {!isMobile && !isVideo(selectedPhoto) && (
            <AiOutlineFullscreen
              size={30}
              className="absolute top-2 right-2 cursor-pointer text-white bg-black bg-opacity-50 rounded-full p-1"
              onClick={e => handleFullScreenToggle(e)}
              title="View Fullscreen"
              style={{ fill: "white" }}
            />
          )}
        </div>
      </div>

      {/* Fullscreen Modal */}
      {isEnlarged &&
        ReactDOM.createPortal(
          <div
            className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}
          >
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 text-white text-3xl z-10"
              onClick={handleClose}
            >
              <FaTimes style={{ fill: "black" }} />
            </button>

            {/* Image Container with Swipe Handlers */}
            <div
              {...(isMobile ? swipeHandlers : {})}
              className="relative"
              style={{
                overflow: "hidden",
                width: "100vw",
                height: "100vh",
              }}
              onClick={handleZoomClick}
              onMouseMove={handleMouseMove}
            >
              {isZoomed ? (
                // Render only the zoomed image
                <img
                  src={imagesForLooping[1]}
                  alt={allKeywords}
                  className="w-screen h-screen object-contain"
                  style={{
                    transform: `scale(${zoomLevel}) translate(${panPosition.x}px, ${panPosition.y}px)`,
                    transformOrigin: `${zoomOrigin.xPercent}% ${zoomOrigin.yPercent}%`,
                    cursor: "zoom-out",
                  }}
                  ref={imgRef}
                />
              ) : (
                // Render the images for swiping
                <div
                  className="flex bg-white unselectable"
                  style={{
                    width: "300%",
                    overflow: "hidden",
                    transform: `translateX(${clampedTransformX}px)`,
                    transition: isTransitioning
                      ? "transform 0.3s ease"
                      : "none",
                  }}
                  onTransitionEnd={handleTransitionEnd}
                >
                  {imagesForLooping.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={allKeywords}
                      className="w-screen h-screen object-contain flex-shrink-0 cursor-zoom-in"
                      ref={index === 1 ? imgRef : null}
                    />
                  ))}
                </div>
              )}
            </div>

            {/* Thumbnails in Fullscreen Modal */}
            {!isMobile && (
              <div
                className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2"
                style={{ zIndex: 10 }}
                onMouseLeave={() => setHoveredPhoto(null)}
              >
                {photos.map((photo, index) => (
                  <img
                    key={index}
                    src={photo}
                    alt={allKeywords}
                    className={`thumbnail-image unselectable w-16 h-16 object-cover cursor-pointer border-2 ${
                      selectedPhoto === photo
                        ? "border-blue-500"
                        : "border-transparent"
                    } hover:border-blue-500`}
                    onMouseEnter={() => {
                      if (photo !== selectedPhoto) {
                        setIsZoomed(false);
                      }
                      setHoveredPhoto(photo);
                    }}
                    onClick={() => {
                      setSelectedPhoto(photo);
                      setHoveredPhoto(null);
                    }}
                  />
                ))}
              </div>
            )}
            {/* Navigation Arrows for Desktop */}
            {!isMobile && (
              <>
                <AiOutlineArrowLeft
                  size={50}
                  className="absolute left-4 text-white cursor-pointer"
                  onClick={() => {
                    prevImage();
                  }}
                  style={{ fill: "black" }}
                />
                <AiOutlineArrowRight
                  size={50}
                  className="absolute right-4 text-white cursor-pointer"
                  onClick={() => {
                    nextImage();
                  }}
                  style={{ fill: "black" }}
                />
              </>
            )}
          </div>,
          document.body,
        )}
    </>
  );
};

export default ImageHandler;

// src/hooks/useSEO.tsx
import { useMemo } from "react";

interface SEOProps {
  title: string;
  description: string;
  image?: string;
  url?: string;
  type?: string;
  locale?: string;
  alternateLocale?: string;
  [key: string]: any; // For additional meta tags
}

const useSEO = ({
  title,
  description,
  image,
  url,
  type = "website",
  locale = "en_US",
  alternateLocale,
  ...otherMeta
}: SEOProps) => {
  const meta = useMemo(
    () => ({
      title,
      description,
      image,
      url,
      type,
      locale,
      alternateLocale,
      ...otherMeta,
    }),
    [title, description, image, url, type, locale, alternateLocale, otherMeta],
  );

  return meta;
};

export default useSEO;

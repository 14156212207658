import React, { useState } from "react";
import Modal from "react-modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface PhoneVerificationModalsProps {
  phoneNumber: string;
  setPhoneNumber: (v: string) => void;
  otpCode: string;
  setOtpCode: (v: string) => void;
  isPhoneModalOpen: boolean;
  isOtpModalOpen: boolean;
  closePhoneModal: () => void;
  closeOtpModal: () => void;
  sendVerification: (countryCode: string) => Promise<void>;
  verifyCode: () => Promise<void>;
  loading: boolean;
}

export const PhoneVerificationModals: React.FC<
  PhoneVerificationModalsProps
> = ({
  phoneNumber,
  setPhoneNumber,
  otpCode,
  setOtpCode,
  isPhoneModalOpen,
  isOtpModalOpen,
  closePhoneModal,
  closeOtpModal,
  sendVerification,
  verifyCode,
  loading,
}) => {
  const [countryCode, setCountryCode] = useState<string>("+972"); // Default country code

  const modalStyles = {
    content: {
      maxWidth: "400px",
      width: "90vw",
      borderRadius: "8px",
      padding: "24px",
      position: "relative",
      margin: "0 auto",
      inset: "0px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    },
  };

  const handlePhoneChange = (value: string, data: any) => {
    // value: the phone number without the leading '+'
    // data: object containing details about the selected country
    setPhoneNumber("+" + value);
    setCountryCode("+" + data.dialCode);
    // Store the selected country code separately
  };

  const handleSendVerification = async () => {
    // Now send both phoneNumber and countryCode to the backend
    await sendVerification(countryCode);
  };

  return (
    <>
      {/* Phone Modal */}
      <Modal
        isOpen={isPhoneModalOpen}
        onRequestClose={closePhoneModal}
        style={modalStyles}
        contentLabel="Phone Number Modal"
      >
        <h2 className="text-lg font-semibold mb-3">Add/Edit Phone Number</h2>
        <div className="mb-3">
          <label className="block mb-1 font-medium">Phone Number</label>
          <PhoneInput
            country="il" // Default country
            value={phoneNumber.replace("+", "")}
            onChange={handlePhoneChange}
            inputClass="border p-2 w-full"
            containerClass="w-full "
            placeholder="Enter phone number"
            // Limit the dropdown height and make it scrollable for small modals
            dropdownStyle={{ maxHeight: "150px", overflowY: "auto" }}
          />
        </div>
        <div className="flex justify-end gap-2">
          <button
            onClick={closePhoneModal}
            className="border border-gray-300 max-md:text-sm px-3 py-1 rounded"
          >
            Cancel
          </button>
          <button
            onClick={handleSendVerification}
            disabled={loading}
            className="bg-blue-500 text-white px-3 py-1 max-md:text-sm rounded hover:bg-blue-600"
          >
            {loading ? "Sending..." : "Send Verification Code"}
          </button>
        </div>
      </Modal>

      {/* OTP Modal */}
      <Modal
        isOpen={isOtpModalOpen}
        onRequestClose={closeOtpModal}
        style={modalStyles}
        contentLabel="OTP Verification Modal"
      >
        <h2 className="text-lg font-semibold mb-3">Enter Verification Code</h2>
        <p className="text-sm mb-4">
          We have sent a verification code to {phoneNumber}.
        </p>
        <div className="mb-3">
          <input
            type="text"
            value={otpCode}
            onChange={e => setOtpCode(e.target.value)}
            className="border p-2 w-full"
            placeholder="Enter the code"
          />
        </div>
        <div className="flex justify-end gap-2">
          <button
            onClick={closeOtpModal}
            className="border border-gray-300 px-3 py-1 rounded"
          >
            Cancel
          </button>
          <button
            onClick={verifyCode}
            disabled={loading}
            className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
          >
            {loading ? "Verifying..." : "Verify"}
          </button>
        </div>
      </Modal>
    </>
  );
};

import React, { useEffect, useRef, useState } from "react";

interface CountdownProps {
  remainingTime: number; // Remaining time in milliseconds
}

const Countdown: React.FC<CountdownProps> = ({ remainingTime }) => {
  const [timeLeft, setTimeLeft] = useState<number>(remainingTime);

  // Calculate the end date based on the remaining time
  const endDate = useRef(new Date(Date.now() + remainingTime));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => (prevTime > 1000 ? prevTime - 1000 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTimeLeft = (ms: number) => {
    if (ms <= 0) return "Auction Ended";

    const totalSeconds = Math.floor(ms / 1000);
    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    const date = endDate.current.toLocaleDateString("en-GB"); // Format as DD/MM
    const time = endDate.current.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    if (ms > 24 * 3600 * 1000) {
      // More than 24 hours
      return `${days}d ${hours}h left (${date} at ${time})`;
    } else if (ms > 3600 * 1000) {
      // Less than 24 hours but more than 1 hour
      return `${hours}h ${minutes}m left (${date} at ${time})`;
    } else if (ms > 5 * 60 * 1000) {
      // Less than 1 hour but more than 5 minutes
      return `${minutes}m ${seconds}s left (${date} at ${time})`;
    } else {
      // Less than 5 minutes
      return `${minutes}m ${seconds}s left (${date} at ${time})`;
    }
  };

  const formattedTime = formatTimeLeft(timeLeft);

  return (
    <div>
      {timeLeft > 24 * 3600 * 1000 ? (
        <div className="countdown-end-date   font-semibold  sm:mt-[-1rem] md:mt-[-1rem] text-lg">
          {formattedTime}
        </div>
      ) : (
        <div
          className={`sm:mt-[-1rem]md:mt-[-1rem] text-lg ${
            timeLeft <= 5 * 60 * 1000 ? "text-red-500 font-bold" : ""
          }`}
        >
          {formattedTime}
        </div>
      )}
    </div>
  );
};

export default Countdown;

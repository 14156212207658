import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Seller from "./Seller";
import Api from "../../api/api";
import { log } from "util";
import { isMobile } from "react-device-detect";

const Sellers: React.FC = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  async function getAllUsers() {
    const res: any = await Api.getUsers("seller", "", 1, 8); // Adjust the API call if needed
    if (res.status === 200) {
      const fetchedUsers = res?.data?.data?.users;

      // Sort users by updatedAt date in descending order
      // fetchedUsers?.sort((a: any, b: any) => {
      //   const dateA = new Date(a.updatedAt).getTime();
      //   const dateB = new Date(b.updatedAt).getTime();
      //   return dateA - dateB;
      // });

      if (isMobile) {
        setUsers(fetchedUsers.slice(0, 4));
        return;
      }
      const topUsers = fetchedUsers.slice(0, 8);

      setUsers(topUsers);
    }
  }

  return (
    <div className="py-8 md:py-12 bg-primary2">
      <div className="mx-auto max-w-[1560px] sm:px-1 md:px-6 w-full">
        <div className="flex items-center gap-2 mb-6">
          <span className="text-2xl md:text-3xl font-bold text-primary">
            Sellers
          </span>
          <p className="w-[2px] h-7 bg-black text-primary"></p>
          <Link
            to="/sellers"
            className="md:text-xl text-primary hover:underline leading-0"
          >
            See all
          </Link>
        </div>
        <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {users?.map((itm, idx) => (
            <Seller
              key={idx}
              itm={itm}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sellers;

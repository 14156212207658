import { useState, useEffect } from "react";
import Api from "../../api/api";
import { IUser } from "../../types/auth";
import toast from "react-hot-toast";

// Custom hook for phone verification
export function usePhoneVerification(user: IUser | undefined) {
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+972"); // default country code
  const [otpCode, setOtpCode] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // If the user already has a phone, pre-fill it
    if (user && user.phone) {
      setPhoneNumber(user.phone);
      // If user.phone is something like "+49123456789", you could infer the country code if needed.
      // Otherwise, you can leave countryCode as default or separate logic to extract it.
    }
  }, [user]);

  const openPhoneModal = () => setIsPhoneModalOpen(true);
  const closePhoneModal = () => setIsPhoneModalOpen(false);
  const openOtpModal = () => setIsOtpModalOpen(true);
  const closeOtpModal = () => setIsOtpModalOpen(false);

  const sendVerification = async () => {
    try {
      setLoading(true);
      const response = await Api.sendVerificationCode({
        phoneNumber,
        countryCode, // Pass the countryCode as well
      });
      setLoading(false);
      if (response.success) {
        closePhoneModal();
        openOtpModal();
      } else {
        // toast.error(response.message);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Failed to send verification code.");
    }
  };
  const verifyCode = async () => {
    try {
      setLoading(true);
      const response = await Api.verifyCode({
        phoneNumber,
        code: otpCode,
        countryCode,
      });
      setLoading(false);
      if (response.success) {
        closeOtpModal();
        return true; // Indicate success
      } else {
        closeOtpModal();
        return false; // Indicate failure
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Failed to verify OTP.");
      return false;
    }
  };

  const verifyCode2 = async () => {
    try {
      setLoading(true);
      const response = await Api.verifyCode({
        phoneNumber,
        code: otpCode,
        countryCode, // Pass the countryCode as well
      });
      setLoading(false);
      if (response.success) {
        // Update user with the newly verified user data
        closeOtpModal();
      } else {
        //toast.error(response.message);
        closeOtpModal();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error("Failed to verify OTP.");
    }
  };

  return {
    phoneNumber,
    setPhoneNumber,
    countryCode,
    setCountryCode,
    otpCode,
    setOtpCode,
    isPhoneModalOpen,
    isOtpModalOpen,
    openPhoneModal,
    closePhoneModal,
    openOtpModal,
    closeOtpModal,
    sendVerification,
    verifyCode,
    loading,
  };
}

// CurrencyContext.tsx
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getUserCurrency,
  getExchangeRates,
  convertPrice,
  convertPriceAsync,
} from "../utils/currencyUtils"; // Utility functions
import { IUser } from "../types/auth";
import Api from "../api/api";
import { useAuth } from "./auth";

interface CurrencyContextType {
  userCurrency: string;
  exchangeRates: Record<string, number>;
  convert: (
    price: number,
    baseCurrency: string,
    targetCurrency: string,
  ) => number;
  convertAsync: (
    price: number,
    baseCurrency: string,
    targetCurrency: string,
  ) => Promise<number>;
  updateUserCurrency: (newCurrency: string) => Promise<void>;
}

const CurrencyContext = createContext<CurrencyContextType | undefined>(
  undefined,
);

export const CurrencyProvider: React.FC<{
  children: React.ReactNode;

  europeanCountries: string[];
}> = ({ children, europeanCountries }) => {
  const { user } = useAuth();
  const [userCurrency, setUserCurrency] = useState<string>("USD");
  const [exchangeRates, setExchangeRates] = useState<Record<string, number>>(
    {},
  );
  const formatPrice = (price: number): number => {
    if (price >= 10 && price < 100) {
      return parseFloat(price.toFixed(1));
    } else if (price >= 100) {
      return Math.round(price);
    } else {
      return parseFloat(price.toFixed(2)); // For prices less than 10
    }
  };
  useEffect(() => {
    const fetchCurrencyAndRates = async () => {
      let currency = "USD";

      // If the user has a lastChosenCurrency, use it
      if (user?.lastChosenCurrency) {
        currency = user.lastChosenCurrency;
      } else {
        // Otherwise, determine currency based on location
        currency = await getUserCurrency();
      }

      const rates = await getExchangeRates(currency);

      setUserCurrency(currency);
      setExchangeRates(rates);
    };

    fetchCurrencyAndRates();
  }, [user, europeanCountries]);

  const convert = (
    price: number,
    baseCurrency: string,
    targetCurrency: string,
  ): number => {
    return formatPrice(
      convertPrice(price, baseCurrency, targetCurrency, exchangeRates),
    );
  };

  const convertAsync = async (
    price: number,
    baseCurrency: string,
    targetCurrency: string,
  ): Promise<number> => {
    return await convertPriceAsync(
      price,
      baseCurrency,
      targetCurrency,
      exchangeRates,
    );
  };

  const updateUserCurrency = async (newCurrency: string) => {
    setUserCurrency(newCurrency);
    if (user) {
      try {
        await Api.updateMyProfile({ lastChosenCurrency: newCurrency });
      } catch (error) {
        console.error("Error updating user's last chosen currency:", error);
      }
    }
  };

  return (
    <CurrencyContext.Provider
      value={{
        userCurrency,
        exchangeRates,
        convert,
        convertAsync,
        updateUserCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error("useCurrency must be used within a CurrencyProvider");
  }
  return context;
};

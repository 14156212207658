import React, { useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Items from "../components/Items/Items";
import Sellers from "../components/Sellers/Sellers";
import Search from "../components/Search";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/auth";
import { Helmet } from "react-helmet";
import AdminHeader from "../components/Admin/AdminHeader";
import { allKeywords } from "../utils/constant";

const Home: React.FC = () => {
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory();
  const { isAdmin } = useAuth();
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchIconClick = () => {
    if (searchValue.trim()) {
      history.push(`/items?q=${encodeURIComponent(searchValue.trim())}`);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && searchValue.trim()) {
      history.push(`/items?q=${encodeURIComponent(searchValue.trim())}`);
    }
  };

  return (
    <>
      <Helmet>
        <title>Numisnest - Numismatics, Coins, Banknotes</title>
        <meta
          name="description"
          content="Buy and sell collectibles like numismatics, coins, banknotes, and stamps on Numisnest."
        />
      </Helmet>
      {isAdmin() ? <AdminHeader /> : <Header />}

      <main>
        <h1 className="collectibles-numismatics-coins-banknotes">
          {allKeywords}
        </h1>
        <div className="mt-7 grid place-items-center mx-auto max-w-[1560px] px-6 w-full">
          <Search
            value={searchValue}
            onChange={handleSearchChange}
            onSearchIconClick={handleSearchIconClick}
            onKeyDown={handleKeyDown}
          />
        </div>
        <Items
          isCountry={true}
          isSorted={true}
          isHomePage={true}
        />
        <Sellers />
      </main>
      <Footer />
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import Seller from "../components/Sellers/Seller";
import Dots from "../assets/dots.svg";
import Search from "../components/Search";
import ReactPaginate from "react-paginate";
import Api from "../api/api";
import { countries } from "../utils/constant";
import { useHistory } from "react-router-dom";
const Sellers: React.FC = () => {
  const [sellers, setSellers] = useState<[]>([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [totalSellers, setTotalSellers] = useState(0);

  const [pageCount, setPageCount] = useState(0);

  const [search, setSearch] = useState<string | undefined>("");
  const [selectedCountry, setSelectedCountry] = useState<string>("everywhere");
  const [filteredSellers, setFilteredSellers] = useState<[]>([]);
  const history = useHistory();
  const perPage = 32;

  const handlePageClick = (event: any) => {
    setItemOffset(event.selected);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: for smooth scrolling
    });
  };
  async function getAllUsers() {
    const res: any = await Api.getUsers(
      "seller",
      search,
      itemOffset + 1,
      perPage,
    );
    if (res.status == 200) {
      const fetchedUsers = res?.data?.data?.users;
      // fetchedUsers?.sort((a: any, b: any) => {
      //   const dateA = new Date(a.updatedAt).getTime();
      //   const dateB = new Date(b.updatedAt).getTime();
      //   return dateA - dateB;
      // });
      setSellers(fetchedUsers);

      setFilteredSellers(res?.data?.data?.users);
      setPageCount(res?.data?.data?.totalPages);
    }
  }
  const sellerCountries = Array.from(
    new Set(sellers.map((seller: any) => seller.country)),
  ).filter(Boolean);
  const availableCountries = countries.filter(country =>
    sellerCountries.includes(country.value),
  );
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);
  useEffect(() => {
    getAllUsers();
  }, [search, itemOffset]);
  useEffect(() => {
    let filtered: any = sellers;

    if (selectedCountry !== "everywhere") {
      filtered = filtered.filter(
        (seller: any) => seller.country && seller.country === selectedCountry,
      );
    }

    if (search) {
      filtered = filtered.filter(
        (seller: any) =>
          seller.fname.toLowerCase().includes(search.toLowerCase()) ||
          seller.lname.toLowerCase().includes(search.toLowerCase()) ||
          seller.email.toLowerCase().includes(search.toLowerCase()) ||
          seller.phone.toLowerCase().includes(search.toLowerCase()),
      );
    }
    setFilteredSellers(filtered);
    // setItemOffset(0);
  }, [selectedCountry, sellers]);
  return (
    <>
      <Header />
      <main>
        <div className="py-8 md:py-12 bg-primary2">
          <div className="mx-auto max-w-[1560px] px-6 w-full">
            <div className="relative flex items-center justify-end gap-2 mb-2">
              <span className="font-medium">Showing sellers from</span>
              <select
                className="rounded-md outline-none border border-black px-3 py-2 text-sm"
                value={selectedCountry}
                onChange={e => setSelectedCountry(e.target.value)}
              >
                <option value="everywhere">Everywhere</option>
                {availableCountries.map(country => (
                  <option
                    key={country.value}
                    value={country.value}
                  >
                    {country.label}
                  </option>
                ))}
              </select>
              <img
                src={Dots}
                alt="dots"
                className="max-md:hidden absolute right-0 top-12 -z-10"
              />
            </div>
            <p className="text-2xl md:text-3xl font-bold mb-3">All Sellers</p>
            <Search
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
            <br />
            <br />
            <div className="grid gap-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {filteredSellers?.map((itm, idx) => (
                <Seller
                  key={idx}
                  itm={itm}
                />
              ))}
            </div>
          </div>
          <br />
          <div>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              containerClassName="flex flex-wrap justify-center mt-4"
              pageClassName="mx-2"
              pageLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              previousClassName="mx-2"
              previousLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              nextClassName="mx-2"
              nextLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              breakClassName="mx-2"
              breakLinkClassName="bg-gray-200 rounded-full grid place-items-center size-[40px]"
              activeClassName="bg-slate-200 rounded-full "
              activeLinkClassName="font-semibold bg-slate-200"
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Sellers;

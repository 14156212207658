import React, { useRef, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs"; // For the dropdown icon
import Avatar from "../../assets/avatar.jpg";
import Modal from "react-modal"; // Import modal for confirmation
import { countries } from "../../utils/constant";
import PhoneInput from "react-phone-input-2";
import Button from "../../common/Button";
import ProfileEditAdmin from "./ProfileEditAdmin";
import topRatedBadge from "../../assets/top_rated_badge.svg";
const AdminSeller = ({
  itm,
  onVerify,
  onUnverify,
  onDelete,
  onUpdate,
  onTopRated,
  onTurnOffTopRated,
  onAuction,
  onBidBan,
}) => {
  const history = useHistory();
  const [hoveredTopRated, setHoveredTopRated] = useState<boolean | null>(null); // For tooltip

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editedSeller, setEditedSeller] = useState(itm);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [conversionRates, setConversionRates] = useState(null);
  // Handle clicks outside dropdown
  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const toggleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen), setDropdownOpen(!dropdownOpen);
  };
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);

  // Handle seller update submission
  const handleUpdate = (id, updatedData) => {
    onUpdate(id, updatedData);
    toggleEditModal();
  };
  const onClickDeletedItems = id => {
    history.push(`deleted-items/${id}`);
  };
  const fetchConversionRates = async () => {
    try {
      const response = await fetch(
        `https://api.exchangerate-api.com/v4/latest/USD`,
      );
      const data = await response.json();
      return data.rates; // Return the rates object, which contains the conversion rates
    } catch (error) {
      console.error("Error fetching conversion rates:", error);
      return {};
    }
  };
  useEffect(() => {
    const getConversionRates = async () => {
      const rates = await fetchConversionRates();
      setConversionRates(rates);
    };

    getConversionRates(); // Fetch rates on component mount
  }, []);

  const renderCurrencyValue = values => {
    let totalInUSDNonHidden = 0;
    let totalInUSDWithHidden = 0;

    if (conversionRates === null) return null;

    // Iterate over each currency in the values object
    Object.keys(values).forEach(currency => {
      const { nonHidden = 0, hidden = 0 } = values[currency] || {};

      const baseToUsd = conversionRates[currency] || 1; // Default to 1 if no conversion rate available
      const nonHiddenInUsd = nonHidden / baseToUsd;
      const hiddenInUsd = hidden / baseToUsd;

      // Accumulate non-hidden and total (nonHidden + hidden) values in USD
      totalInUSDNonHidden += nonHiddenInUsd;
      totalInUSDWithHidden += nonHiddenInUsd + hiddenInUsd;
    });

    return (
      <p className="mb-1 whitespace-nowrap  line-clamp-1">
        <span className="font-semibold">Total value in USD: </span>
        {totalInUSDNonHidden.toFixed(0)}$ ({totalInUSDWithHidden.toFixed(0)}$)
      </p>
    );
  };
  return (
    <div className="relative">
      <div className="block bg-white rounded-xl shadow-lg p-4 hover:shadow-xl transition-shadow duration-300">
        <div className="flex items-center justify-center gap-2 mb-3">
          <img
            className="w-6 h-4 rounded"
            src={`https://flagcdn.com/32x24/${itm?.country?.toLowerCase()}.png`}
            alt={`${itm?.country} flag`}
          />
          <p className="font-semibold text-lg text-gray-800">
            {itm?.fname + " " + itm?.lname}
          </p>
        </div>
        {itm?.topRated && (
          <div
            className="absolute left-0.5 top-1"
            onMouseEnter={() => setHoveredTopRated(true)}
            onMouseLeave={() => setHoveredTopRated(null)}
          >
            <img
              className="w-[13px] sm:max-w-[15px] md:min-w-[25px] "
              src={topRatedBadge}
              alt={`Top Rated Seller`}
            />

            {/* Tooltip */}
            {hoveredTopRated && (
              <div className="absolute whitespace-nowrap bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-gray-900 text-white text-xs rounded-md px-2 py-1 z-10">
                {"Top Rated Seller"}
              </div>
            )}
          </div>
        )}
        <div className="flex justify-center mb-4">
          <div className="w-24 h-24 rounded-full overflow-hidden border border-gray-300">
            <img
              className="w-full h-full object-cover"
              src={itm?.avatar || Avatar}
              alt="Avatar"
            />
          </div>
        </div>

        <div className="text-center mb-4">
          <p
            className="text-gray-600 text-sm"
            style={{ minHeight: "3.75rem", maxHeight: "3.75rem" }}
          >
            {itm?.about || "No description available"}
          </p>
        </div>

        <div className="text-sm text-gray-500">
          <p className="mb-1">
            <span className="font-semibold text-gray-700">Member since: </span>
            {new Date(itm?.createdAt).toLocaleDateString("en-GB")}
          </p>
          <p className="mb-1">
            <span className="font-semibold text-gray-700">E-mail:</span>

            {" " + itm?.email || "Not present"}
          </p>
          <p className="mb-1">
            <span className="font-semibold text-gray-700">Phone number:</span>

            {" +" + itm?.phone || "Not present"}
          </p>
          <p className="mb-1">
            <span className="font-semibold text-gray-700">
              Number of items:{" "}
            </span>
            {itm?.totalItems || 0}
          </p>
          {renderCurrencyValue({
            USD: {
              nonHidden: itm?.totalValueNonHidden?.USD || 0,
              hidden: itm?.totalValueHidden?.USD || 0,
            },
            EUR: {
              nonHidden: itm?.totalValueNonHidden?.EUR || 0,
              hidden: itm?.totalValueHidden?.EUR || 0,
            },
            ILS: {
              nonHidden: itm?.totalValueNonHidden?.ILS || 0,
              hidden: itm?.totalValueHidden?.ILS || 0,
            },
          })}
          <p className="mb-1">
            <span className="font-semibold text-gray-700">
              Number of collection:{" "}
            </span>
            {itm?.totalCollection || 0}
          </p>

          <p className="mb-1">
            <span className="font-semibold text-gray-700">
              Email verified:{" "}
            </span>
            {itm?.isVerified ? (
              <span className="font-semibold text-green-600">{"Yes"}</span>
            ) : (
              <span className="font-semibold text-red-600">{"No"}</span>
            )}
          </p>
          <p className="mb-1">
            <span className="font-semibold text-gray-700">
              Last time online:{" "}
            </span>
            {new Date(itm?.updatedAt).toLocaleDateString("en-GB")}
          </p>
          <p className="mb-1">
            <span className="font-semibold text-gray-700">
              Visits (d/w/m):{" "}
            </span>
            {itm?.visitStats?.daily?.count || 0}/
            {itm?.visitStats?.weekly?.count || 0}/
            {itm?.visitStats?.monthly?.count || 0}
          </p>
          <p
            className={`text-center font-bold ${itm?.isVerified && itm?.isVerifiedByAdmin ? "text-green-600" : "text-red-600"}`}
          >
            {itm?.isVerified && itm?.isVerifiedByAdmin ? "Approved" : "Pending"}
          </p>
        </div>
      </div>

      <div className="absolute top-2 right-2">
        <button
          className="text-gray-600 hover:text-gray-900"
          onClick={toggleDropdown}
        >
          <BsThreeDotsVertical className="w-5 h-5" />
        </button>
      </div>

      {dropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute right-2 top-8 bg-white border border-gray-200 rounded-md shadow-lg z-10 w-32"
        >
          <ul className="py-1">
            <li
              className="cursor-pointer px-1 py-2 hover:bg-gray-100"
              onClick={toggleEditModal}
            >
              Edit
            </li>
            <li
              className="cursor-pointer px-1 py-2 hover:bg-gray-100"
              onClick={() => history.push(`user/history/${itm?._id}`)}
            >
              History
            </li>
            <li
              className="cursor-pointer px-1 py-2 hover:bg-gray-100"
              onClick={() => history.push(`user/messages/${itm?._id}`)}
            >
              Messages
            </li>
            {itm?.topRated ? (
              <li
                className="cursor-pointer px-1 py-2 hover:bg-gray-100 line-clamp-1"
                onClick={() => {
                  onTurnOffTopRated(itm._id);
                  setDropdownOpen(false);
                }}
              >
                UnTopRated
              </li>
            ) : (
              <li
                className="cursor-pointer px-1 py-2 hover:bg-gray-100"
                onClick={() => {
                  onTopRated(itm._id);
                  setDropdownOpen(false);
                }}
              >
                Top Rated
              </li>
            )}

            {itm?.isAuctioneer ? (
              <li
                className="cursor-pointer  px-1 py-2 hover:bg-gray-100 line-clamp-1"
                onClick={() => {
                  onAuction(itm._id);
                  setDropdownOpen(false);
                }}
              >
                TurnOffAuction
              </li>
            ) : (
              <li
                className="cursor-pointer px-1 py-2 hover:bg-gray-100"
                onClick={() => {
                  onAuction(itm._id);
                  setDropdownOpen(false);
                }}
              >
                TurnOnAuction
              </li>
            )}
            {itm?.isBannedFromBidding ? (
              <li
                className="cursor-pointer px-1 py-2 hover:bg-gray-100 line-clamp-1"
                onClick={() => {
                  onBidBan(itm._id);
                  setDropdownOpen(false);
                }}
              >
                UnBanBid
              </li>
            ) : (
              <li
                className="cursor-pointer px-1 py-2 hover:bg-gray-100"
                onClick={() => {
                  onBidBan(itm._id);
                  setDropdownOpen(false);
                }}
              >
                BanBidding
              </li>
            )}
            <li
              className="cursor-pointer px-1 py-2  hover:bg-gray-100"
              onClick={() => onClickDeletedItems(itm?._id)}
            >
              Deleted Items
            </li>

            {itm?.isVerified && itm?.isVerifiedByAdmin ? (
              <li
                className="cursor-pointer px-1 py-2 hover:bg-gray-100"
                onClick={() => {
                  onUnverify(itm._id);
                  setDropdownOpen(false);
                }}
              >
                Unverify
              </li>
            ) : (
              <li
                className="cursor-pointer px-1 py-2 hover:bg-gray-100"
                onClick={() => {
                  onVerify(itm._id);
                  setDropdownOpen(false);
                }}
              >
                Verify
              </li>
            )}
            <li
              className="cursor-pointer px-1 py-2 text-red-600 hover:bg-gray-100"
              onClick={toggleDeleteModal}
            >
              Delete
            </li>
          </ul>
        </div>
      )}

      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={toggleDeleteModal}
        contentLabel="Delete Seller"
        className="bg-white p-6 rounded-lg shadow-xl max-w-md mx-auto mt-24"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-lg font-bold mb-4">Delete Seller</h2>
        <p className="mb-6">
          Are you sure you want to delete{" "}
          <strong>
            {itm?.fname} {itm?.lname}
          </strong>
          ?
        </p>
        <div className="flex justify-end gap-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            onClick={toggleDeleteModal}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
            onClick={() => {
              onDelete(itm._id);
              toggleDeleteModal();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={toggleEditModal}
        contentLabel="Edit Seller"
        className="bg-white p-6 rounded-lg shadow-xl max-w-md mx-auto mt-24"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-10000"
        style={{
          content: {
            zIndex: 10000, // Ensuring z-index is high enough to cover the dropdown
            maxHeight: "80vh", // Controlling the maximum height of the modal
            overflowY: "auto", // Enabling scrolling if the content exceeds the height
          },
        }}
      >
        <h2 className="text-lg font-bold mb-4">Edit Seller</h2>
        <ProfileEditAdmin
          sellerData={itm}
          onSave={handleUpdate}
          onCancel={toggleEditModal}
        />
      </Modal>
    </div>
  );
};

export default AdminSeller;
